<template>
  <panel title="监控报修记录">
    <seamless-scroll :data="infoList" :class-option="classOption">
      <div class="roll-item" v-for="(item, index) in infoList" :key="index">
        <div>
          <span class="_title">地址&emsp;</span>
          {{ item.address }}
        </div>
        <div>
          <span class="_title">名称&emsp;</span>
          {{ item.name }}
        </div>
        <div>
          <span class="_title">时间&emsp;</span>
          {{ item.time }}
        </div>
        <div>
          <span class="_title">等级&emsp;</span>
          {{ item.level }}
        </div>
      </div>
    </seamless-scroll>
  </panel>
</template>

<script>
export default {
  data() {
    return {
      infoList: [
        {
          id: 1,
          address: "阿瓦提县北京中路",
          name: "北京中路2号监控不在线",
          time: "2022年5月9日 11：08",
          level: "一般",
        },
        {
          id: 2,
          address: "阿瓦提县浙江西路",
          name: "浙江西路12号监控不在线",
          time: "2022年5月9日 13：23",
          level: "一般",
        },
        {
          id: 3,
          address: "阿瓦提县文明中路",
          name: "文明西路36号监控不在线",
          time: "2022年5月11日 02：08",
          level: "一般",
        },
        {
          id: 4,
          address: "阿瓦提县发展中路",
          name: "发展中路43号监控不在线",
          time: "2022年5月12日15：24",
          level: "急",
        },
        {
          id: 5,
          address: "阿瓦提县北京北路",
          name: "北京北路23号监控不在线",
          time: "：2022年5月13日 16：18",
          level: "急",
        },
      ],
      classOption: {
        step: 0.85, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000,
      },
    };
  },
};
</script>