<template>
  <div></div>
</template>

<script>
import { getEnergy } from "@/api/data";
export default {
  data() {
    return {
      chart2: null,
      num: "12",
      xdata: [],
      ydata: [],
    };
  },
  methods: {
    getLampEnergy() {
      this.xdata = [];
      this.ydata = [];
      getEnergy().then((res) => {
        res.map(r => {
          this.xdata.push(r.reportDate.toString().replace(/(.{4})(.{2})/, '$1-$2-'))
          this.ydata.push(r.total)
        });
        this.init()
      });
    },
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      this.chart2.clear();
      this.chart2.setOption({
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
        },
        grid: {
          top: 40,
          left: 10,
          right: 60,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xdata,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "故障数",
            type: "line",
            data: this.ydata,
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.getLampEnergy();
    this.init();
  },
};
</script>