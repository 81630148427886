<template>
  <div class="component-panel">
    <div class="_border"></div>
    <div class="_back"></div>
    <div class="_title" v-if="title">
      {{title}}
      <slot name="title"></slot>
    </div>
    <div class="_sep"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
.component-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  // margin-bottom: -10px;
  margin: -5px;
  overflow: hidden;

  // &:last-child {
  //   margin-bottom: 0;
  // }

  > ._title {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: initial;
    z-index: 2;
    border: transparent solid;
    border-width: 15px 15px 0 15px;
    padding: 10px;
    line-height: 1;
    font-weight: bold;
    color: rgb(120, 238, 252);
    position: relative;
    text-align: center;
    background-image: url(/images/panel-title-back.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      content: "";
      background: linear-gradient(
        to right,
        transparent,
        #2e90f599,
        transparent
      );
    }

    &:after {
      position: absolute;
      border-left: transparent solid 5px;
      border-right: transparent solid 5px;
      border-top: #2e90f5 solid 5px;
      left: 50%;
      bottom: -4px;
      margin-left: -5px;
      width: 0;
      height: 0;
      content: "";
    }
  }

  > ._sep + div {
    flex: 1;
    z-index: 3;
    margin: 15px;
    padding-bottom: 10px;
    overflow: hidden;
  }

  > ._border {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: transparent solid 30px;
    border-image: url("/images/panel-border.png") 30 30 30 30 stretch;
  }

  > ._back {
    position: absolute;
    pointer-events: none;
    top: 30px;
    right: 30px;
    left: 30px;
    bottom: 30px;
    background-color: #000032;
    z-index: 2;
  }
}
</style>