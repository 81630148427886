<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    init() {
      let chart = echarts.init(this.$el, "owner");
      var option = {
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: [
            "2022-04",
            "2022-03",
            "2022-02",
            "2022-01",
            "2021-12",
            "2021-11",
            "2021-10",
            "2021-09",
            "2021-08",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name:'PM2.5',
            data: [86, 70, 83, 97, 92, 105, 75, 93,72,79],
            type: "bar",
            barMaxWidth: 30,

            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },
          },
          {
            name:'PM10',
            data: [103, 92, 88, 95, 101, 85, 95, 108, 82, 90],
            type: "bar",
            barMaxWidth: 30,

            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
};
</script>