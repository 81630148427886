<template>
  <transition
    enter-active-class="animated faster bounceIn"
    leave-active-class="animated faster bounceOut"
  >
    <div class="app-assembly-dashboard-details v" v-if="visible">
      <template v-if="data">
        <div class="h s header no-flex">
          <div class="title flex" style="margin: 0">{{ data.name }}</div>
          <div class="close fa-solid fa-xmark no-flex" @click="doClose"></div>
        </div>
        <div class="content">
          <template v-if="data.type === 'residential'">
            <village :data="data"></village>
          </template>

          <template v-else-if="data.type === 'equipment'">
            <div class="padding-20-0">
              设备名称：{{ data.name }}
              <br />
              设备类型：{{ data.equipType ? data.equipType.name : "" }}
              <br />
              设备型号：{{ data.model }}
              <br />
              制造厂商：{{ data.manufacturer }}
              <br />
              维护方：{{ data.maintenance }}
            </div>
            <div class="op-60 ta-c padding-20-0">该设备暂无维修记录</div>
          </template>
          <template v-else-if="data.type === 'cover'">
            <cover-detail :data="data" />
            <alarm />
          </template>
          <template v-else-if="data.type === 'lamp'">
            <lamp-detail :data="data" />
            <div class="h c m" style="font-size: 18px; color: white; font-weight: bold">
              集中器信息
            </div>
            <!-- <mpc-info v-if="mpcInfo" :mpcInfo="mpcInfo"></mpc-info> -->
          </template>
          <template v-else-if="data.type === 'trafficlight'">
            <trafficlight-detail :data="data" />
            <alarm />
          </template>

          <template
            v-else-if="
              data.type === 'camera' ||
              data.type === 'heatcamera' ||
              data.type === 'gascamera' ||
              data.type === 'sewagecamera'
            "
          >
            <monitor-detail ref="monitor" :data="data"></monitor-detail>
          </template>

          <template v-else-if="data.type === 'zfdevice'">
            <enforce-detail ref="enforce" :data="data"></enforce-detail>
          </template>

          <template v-else-if="data.type === 'enforce-law'">
            <div class="padding-20-0">
              事件名称：XXXXXXX
              <br />事件描述：XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
              <br />事件地点：XXX省XX市XX区XX路100号 <br />记录人员：XXX
              <br />记录时间：2021-09-29 13:46:34 <br />处理结果：XXXXXXXXXXX
              <br />处理人员：XXX <br />处理时间：2021-09-29 14:21:55
            </div>
          </template>
          <template v-else-if="data.type === 'parking'">
            <div class="h padding-20-0">
              <number-view
                :value="parseFloat(data.total_lot) || Math.random() * 100"
                title="总车位"
                icon="las la-parking"
                style="width: 50%"
              />
              <number-view
                :value="parseFloat(data.empty_lot) || Math.random() * 100"
                title="剩余车位"
                icon="las la-car"
                style="width: 50%"
              />
            </div>
            <div class="padding-20-0">
              停车场名称：{{ data.name }}
              <br />
              停车场负责人：{{ data.linkman }}
              <br />
              联系方式：{{ data.mobile }}
              <br />
              地址：{{ data.address }}
            </div>
          </template>
        </div>
      </template>
    </div>
  </transition>
</template>
<script>
import { getParkingLot, getauth, getLampInfo, getMpcInfo } from "@/api/data";
import Village from "./village.vue";
import CoverDetail from "./coverdetail.vue";
import LampDetail from "./lampdetail.vue";
import TrafficlightDetail from "./trafficlightdetail.vue";
import MonitorDetail from "./monitordetail.vue";
import EnforceDetail from "./enforcedetail.vue";
import Alarm from "./alarm";
import MpcInfo from "./mpcInfo.vue"
export default {
  props: {
    data: Object,
    visible: Boolean,
  },
  data() {
    return {
      lampInfo: null,
      mpcInfo: null,
    };
  },
  components: {
    Village,
    CoverDetail,
    LampDetail,
    TrafficlightDetail,
    MonitorDetail,
    EnforceDetail,
    Alarm,
    MpcInfo
  },

  watch: {
    visible: {
      handler: function (val) {
        if (val && this.data && this.data.type === "parking") {
          this.getParkLot();
        }
        if (val && this.data && this.data.type === "zfdevice") {
          this.$nextTick(() => {
            this.$refs.enforce.initAuth();
          });
        }
        if (val && this.data && this.data.type === "lamp") {
          this.getLampInfoById(this.data.id);
          this.getMpcInfoById(this.data.originInfo.deviceId);
        }
      },
    },
  },

  methods: {
    getLampInfoById(id) {
      getLampInfo({ lampKey: id }).then((res) => {
        this.lampInfo = res.content[0];
      });
    },
    getMpcInfoById(id) {
      getMpcInfo(id).then((res) => {
        this.mpcInfo = res;
        console.log(this.mpcInfo)
      });
    },
    getParkLot() {
      getParkingLot(this.data.sn).then((res) => {
        var r = JSON.parse(res);
        if (r.status == 0) {
          this.data.empty_lot = 0;
          this.data.total_lot = 0;
        } else {
          var temp = JSON.parse(JSON.parse(res).data);
          this.data.empty_lot = temp.empty_lot;
          this.data.total_lot = temp.total_lot;
        }
      });
    },
    doClose() {
      if (
        this.data.type === "camera" ||
        this.data.type === "heatcamera" ||
        this.data.type === "gascamera" ||
        this.data.type === "sewagecamera"
      ) {
        this.$refs.monitor.isPlay = false;
      }
      this.$emit("update:visible", false);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.app-assembly-dashboard-details {
  position: fixed;
  right: 50px;
  top: 160px;
  bottom: 200px;
  width: 480px;
  background-color: #000032;
  border: #2e90f5 solid 3px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  z-index: 990;
  color: white;
  text-align: justify;

  > .header {
    line-height: 1;
    > .title {
      padding: 20px 20px 0 20px;
      font-size: 18px;
      color: white;
      font-weight: bold;
    }

    > .close {
      background-color: #2e90f5;
      color: #ffffff99;
      padding: 10px 10px 13px 13px;
      border-radius: 0 0 0 10px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: #ffffff;
      }
    }
  }

  > .content {
    padding: 10px 20px;
    flex: 1;
    overflow: hidden auto;
    line-height: 1.6;
  }

  .images {
    height: 120px;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    > div {
      margin-right: 10px;
      flex: 1;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>