<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      chart2: null,
      num: "12",
    };
  },
  methods: {
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      // 模拟数据
      let d1 = [], d2 = [];
      for (let i = 0; i < 15; i++) {
        let t = Math.round(Math.random() * 300) + 200;
        d1.push(t);
        d2.push(t + Math.round(Math.random() * 60) - 30);
      }

      this.chart2.clear();
      this.chart2.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 20,
          left: 10,
          right: 10,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "02/01",
            "02/02",
            "02/03",
            "02/04",
            "02/05",
            "02/06",
            "02/07",
            "02/08",
            "02/09",
            "02/10",
            "02/11",
            "02/12",
            "02/13",
            "02/14",
            "02/15",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },

            data: d1,
            type: "bar",
            name: "进场"
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908cc" },
                  { offset: 1, color: "#F5390800" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#F5390833" },
                ]),
              },
            },

            data: d2,
            type: "bar",
            name: "离场"
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>