<template>
  <div v-if="visible" class="app-assembly-elamp v">
      <div class="_close el-icon-close no-flex" @click="lampClose"></div>
    <lamp-detail :ismpc='data.isMpc' :data="lampInfo" :mpcdata="mpcInfo" class="_detail"></lamp-detail>
  </div>
</template>

<script>
import { getLampInfo, getMpcInfo } from "@/api/data";
import lampDetail from "./lampdetail.vue";
export default {
  props: {
    visible: Boolean,
    data: Object,
  },
  components: {
    lampDetail,
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val && this.data) {
          if (!this.data.isMpc) {
            this.getLampInfoById(this.data.id);
            this.getMpcInfoById(this.data.originInfo.deviceId);
          } else {
            this.getMpcInfoById(this.data.originInfo.id);
          }
        }
      },
    },
  },
  data() {
    return {
      lampInfo: null,
      mpcInfo: null,
    };
  },
  methods: {
    getLampInfoById(id) {
      getLampInfo({ lampKey: id }).then((res) => {
        if (res && res.length > 0) {
          this.lampInfo = res[0];
          console.log(this.lampInfo,'lamp')
        }
      });
    },
    getMpcInfoById(id) {
      getMpcInfo({ id }).then((res) => {
        if (res && res.length > 0) {
          this.mpcInfo = res[0];
          console.log(this.mpcInfo,'mpc')

        }
      });
    },
    lampClose() {
      this.$emit("visibleHandle");
    },
  },
};
</script>

<style lang="scss">
.app-assembly-elamp {
  position: fixed;
  top: 50px;
  width: 900px;
  left: 50%;
  margin-left: -450px;
  bottom: 100px;
  background-color: #000032;
  color: white;
  z-index: 9999;
  border: #1d1ddd solid 1px;
  box-shadow: 0 0 20px rgba(0,0,0,.6);
  ._detail {
    flex: 1;
    overflow: hidden auto;
  }
  ._close {
    background-color: #004e9d;
    width: 30px;
    height: 30px;
    margin: 10px;
    align-self: flex-end;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
  }
}
</style>