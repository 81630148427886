<template>
  <div class="assembly-video">
    <template v-if="finalCode">
      <div :id="finalCode" :style="{width: `${finalWidth}px`, height: `${finalHeight}px`}"></div>
      <div class="_error" v-if="error">{{error}}</div>
      <div class="_controls h c m" v-else>
        <div class="_btn" :class="isPlaying ? 'fa-solid fa-stop' : 'fa-solid fa-play'" @click="doStop"></div>
        <div class="_btn" :class="isFullscreen ? 'fa-solid fa-compress' : 'fa-solid fa-expand'" @click="doFullscreen"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { getCameraPlayUrl } from "@/api/data";

export default {
  props: {
    code: String,
    width: Number,
    height: Number,
    single: Boolean,
  },
  data() {
    return {
      finalCode: null,
      instance: null,
      url: null,
      error: false,
      isPlaying: false,
      isFullscreen: false,
      windowIndex: 0,
      finalWidth: 0,
      finalHeight: 0,
    };
  },
  watch: {
    code: "init",
  },
  methods: {
    init() {
      if (this.code && window.JSPlugin) {
        this.finalCode = (this.single ? "s_" : "") + this.code;
        this.finalWidth = this.width;
        this.finalHeight = this.height;
        if (!this.finalWidth) this.finalWidth = this.$el.offsetWidth;
        if (!this.finalHeight)
          this.finalHeight = Math.round((this.$el.offsetWidth / 16) * 9);

        if (window.cameraUrls[this.code]) {
          setTimeout(() => {
            this.url = window.cameraUrls[this.code];
            if (!this.instance) this.createVideo();
            else this.doPlay();
          }, 1000);
        } else {
          getCameraPlayUrl(this.code)
            .then((res) => {
              this.error = null;
              window.cameraUrls[this.code] = res.url;
              this.url = res.url;
              if (!this.instance) this.createVideo();
              else this.doPlay();
            })
            .catch(() => {
              this.error = "未能找到对应的监控视频地址，请检查视频监控配置";
            });
        }
      }
    },
    createVideo() {
      this.instance = new JSPlugin({
        szId: this.finalCode,
        szBasePath: "/",
        iWidth: this.finalWidth,
        iHeight: this.finalHeight,
        iMaxSplit: 1,
        iCurrentSplit: 1,
        oStyle: {
          borderWidth: 0,
        },
      });
      this.instance
        .JS_SetWindowControlCallback({
          windowEventSelect(iWndIndex) {
            this.windowIndex = iWndIndex;
          },

          windowFullCcreenChange(full) {
            this.isFullscreen = full;
          },
          performanceLack() {
            this.error = "当前电脑性能不足，无法继续播放监控视频";
          },
        })
        .then(() => {
          this.instance
            .JS_SetOptions({
              bSupportSound: true, // 是否支持音频，默认支持
              bSupporDoubleClickFull: true, // 是否双击窗口全屏，默认支持
              bOnlySupportMSE: false, // 只支持MSE
              bOnlySupportJSDecoder: true, // 只支持JSDecoder
            })
            .then(this.doPlay);
        });
    },
    doPlay() {
      this.instance
        .JS_Play(
          this.url,
          {
            playURL: this.url,
            mode: 0,
          },
          this.windowIndex
        )
        .then(() => {
          this.error = null;
          this.isPlaying = true;
        })
        .catch((e) => {
          this.error = e.message || "播放监控视频发生错误";
        });
    },
    doStop() {
      if (this.isPlaying) {
        this.instance.JS_StopRealPlayAll().then(() => {
          this.isPlaying = false;
        });
      } else {
        this.doPlay();
      }
    },
    doFullscreen() {
      this.instance.JS_FullScreenDisplay(!this.isFullscreen);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.instance && this.isPlaying) this.doStop();
  },
};
</script>

<style lang="scss">
.assembly-video {
  position: relative;
  border: #2e90f5 solid 1px;
  display: flex;
  overflow: hidden;
  .sub-wnd {
    border: none;
  }
  > ._error {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000032cc;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #f56c6c;
  }
  > ._controls {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent, #000000cc);
    padding: 0.5rem 0;
    opacity: 0;
    transition: opacity 0.3s;

    > ._btn {
      padding: 10px;
      width: 1.28rem;
      height: 1.28rem;
      line-height: 1.28rem;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      margin: 0.5rem;

      &:hover {
        background-color: #000032;
        color: white;
      }
    }
  }

  &:hover > ._controls {
    opacity: 1;
  }
}
</style>