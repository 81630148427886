/**
 * 用于封装一些常用的调用方法
 */

let _user = null;
let _loadingTimer = null;
let weekNames0 = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
let weekNames1 = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

function _isArray(o, strict = false) {
  let isarray = Object.prototype.toString.call(o) === '[object Array]'
  return isarray && (!strict || o.length > 0)
}


let _sys_now = null; let _sys_now_timer = null;
const _sys_now_step = 1000;
const _sys_now_update = function () {
  _sys_now += _sys_now_step;
  for (let tk in _sys_time_listener) {
    if (_sys_time_listener.hasOwnProperty(tk) && tk <= _sys_now) {
      _sys_time_listener[tk].forEach(fn => {
        fn();
      });
      delete _sys_time_listener[tk];
    }
  }
};
const _sys_time_listener = {};

export default {
  install(Vue, args) {
    String.prototype.format = function (args) {
      let result = this;
      if (arguments.length > 0) {
        if (arguments.length === 1 && typeof (args) === 'object') {
          for (let key in args) {
            if (args[key] !== undefined) {
              let reg = new RegExp('({' + key + '})', 'g');
              result = result.replace(reg, args[key] === null ? '' : String(args[key]));
            }
          }
        } else {
          for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] !== undefined) {
              let reg = new RegExp('({)' + i + '(})', 'g');
              result = result.replace(reg, arguments[i] === null ? '' : String(arguments[i]));
            }
          }
        }
      }
      return result;
    }

    String.prototype.getParameter = function (key) {
      let re = new RegExp(key + '=([^&]*)(?:&)?')
      return this.match(re) && this.match(re)[1]
    }

    Date.prototype.format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
      let o = {
        'M+': this.getMonth() + 1,                 // 月份
        'd+': this.getDate(),                    // 日
        'h+': this.getHours(),                   // 小时
        'm+': this.getMinutes(),                 // 分
        's+': this.getSeconds(),                 // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'W': weekNames0[this.getDay()],
        'w': weekNames1[this.getDay()],
        'S': this.getMilliseconds()             // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    }

    Date.prototype.convert = function (str) {
      if (typeof str === "string") {
        let strs = str.split(" ");
        let ymd = strs[0].replace(/\-/ig, "/").split("/");
        ymd = ymd.map((s, i) => {
          if (i === 1) return parseInt(s) - 1;
          return parseInt(s);
        });
        if (strs.length > 1) {
          let hms = strs[1].split(":");
          hms = hms.map(s => {
            return parseInt(s);
          });

          ymd = ymd.concat(hms);
        }

        return new Date(...ymd);
      } else if (typeof str === "number") {
        return new Date(str);
      }
      return str;
    }

    Date.prototype.getWeek = function () {
      const firstDate = new Date(this.getFullYear(), 0, 1)
      const sDate = new Date(this.getFullYear(), this.getMonth(), this.getDate())
      let shiftDays = firstDate.getDay()
      if (shiftDays === 0) shiftDays = 7
      return Math.ceil(((sDate - firstDate) / 86400000 + shiftDays) / 7)
    }

    Date.prototype.getWeekDates = function (sameMonth = false) {
      let dinx = this.getDay() - 1; const ct = this.getTime(); const month = this.getMonth()
      if (dinx < 0) dinx = 6
      const arr = []
      for (let i = -dinx, l = 7 - dinx; i < l; i++) {
        const d = new Date(ct + i * 86400000)
        if (!sameMonth || d.getMonth() === month) arr.push(d)
      }
      return arr
    }

    Date.prototype.getSystemTime = function () {
      return new Date(_sys_now);
    }

    if (!Array.prototype.find) {
      Array.prototype.find = function (fn) {
        var o = null;
        if (typeof fn === 'function') {
          for (var i = 0, ii = this.length; i < ii; i++) {
            var item = this[i];
            if (fn(item) === true) {
              o = item;
              break;
            }
          }
        }
        return o;
      };
    }

    if (!Array.prototype.findIndex) {
      Array.prototype.findIndex = function (fn) {
        var o = -1;
        if (typeof fn === 'function') {
          for (var i = 0, ii = this.length; i < ii; i++) {
            var item = this[i];
            if (fn(item) === true) {
              o = i;
              break;
            }
          }
        }
        return o;
      };
    }

    if (!Array.prototype.$query) {
      Array.prototype.$query = function (val, valueField = "id", displayField = "name", childrenField = "children", fullPath = true) {
        let v = [], labels = [];
        let fn = (source, level = 0, output = []) => {
          let exist = false;
          output[level] = -1;
          labels[level] = "";
          if (source && source.length) {
            for (let i = 0, l = source.length; i < l; i++) {
              let sub = source[i];
              if (
                sub &&
                sub.hasOwnProperty(valueField) &&
                sub[valueField] === val
              ) {
                exist = true;
              } else if (
                sub.hasOwnProperty(childrenField) &&
                sub[childrenField] &&
                sub[childrenField].length
              ) {
                exist = fn(sub[childrenField], level + 1, output);
              }
              if (exist) {
                output[level] = i;
                if (!fullPath) labels[0] = sub[displayField];
                else labels[level] = sub[displayField];
                break;
              }
            }
          }
          return exist;
        };
        fn(this, 0, v);
        return {
          values: v,
          labels: labels
        };
      }
    }

    Vue.prototype.$config = {};

    Vue.prototype.$time = {
      get: function () {
        return _sys_now
      },
      set: function (val) {
        if (_sys_now_timer) clearInterval(_sys_now_timer)
        _sys_now = val
        _sys_now_timer = setInterval(_sys_now_update, _sys_now_step)
      },
      getDiffDays(days) {
        return _sys_now + (days * 24 * 60 * 60 * 1000)
      },
      addEventListener(time, fn) {
        if (time && typeof fn === "function") {
          let tk = typeof time === "number" ? time : new Date(time).getTime();
          if (typeof tk === "number" && !isNaN(tk)) {
            if (!_sys_time_listener.hasOwnProperty(tk)) {
              _sys_time_listener[tk] = [];
            }
            _sys_time_listener[tk].push(fn);
          }
        }
      },
      removeEventListener(time, fn) {
        let tk = typeof time === "number" ? time : new Date(time).getTime();
        if (typeof tk === "number" && !isNaN(tk) && _sys_time_listener.hasOwnProperty(tk)) {
          if (typeof fn === "function") {
            let inx = _sys_time_listener[tk].indexOf(fn);
            if (inx > -1) _sys_time_listener[tk].splice(inx, 1);
          } else {
            delete _sys_time_listener[tk];
          }
        }
      }
    }

    Vue.prototype.$clipboard = {
      write(data) {
        if (data && typeof data === "string") {
          const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
          const fakeElement = document.createElement('textarea');
          // Prevent zooming on iOS
          fakeElement.style.fontSize = '12pt';
          // Reset box model
          fakeElement.style.border = '0';
          fakeElement.style.padding = '0';
          fakeElement.style.margin = '0';
          // Move element out of screen horizontally
          fakeElement.style.position = 'absolute';
          // fakeElement.style[isRTL ? 'right' : 'left'] = '-9999px';
          // Move element to the same position vertically
          let yPosition = window.pageYOffset || document.documentElement.scrollTop;
          fakeElement.style.top = `${yPosition}px`;

          fakeElement.setAttribute('readonly', '');
          fakeElement.value = data;

          document.body.append(fakeElement);
          fakeElement.select();
          document.execCommand("copy", false);
          document.body.removeChild(fakeElement);

          Vue.prototype.$plugin.toast("已复制");
        }
      }
    }

    Vue.prototype.$plugin = {
      toast: function (message, success, params = {}) {
        if (window.WebPlugin) {
          let p = Object.assign(success === true ? { placement: "top", "background": "#EE67C23A", offsetY: 200 } : { placement: "top", "background": "#EEF56C6C", offsetY: 200 }, params, typeof message === 'string' ? {
            message: message
          } : message)
          window.WebPlugin.do("toast", p);
        } else if (Vue.$message) {
          Vue.$message[success === true ? "success" : "error"](message);
        } else if (window.AppRoot) {
          window.AppRoot.showToast(message, success, params);
        } else {
          alert(message);
        }
      },
      confirm: function (title, message, options) {
        if (window.AppRoot) {
          if (title || message) {
            return window.AppRoot.showConfirm(title, message, options)
          }
        }
        return new Promise((resolve, reject) => { reject() });
      },
      notify: function (data) {
        if (window.WebPlugin) {
          window.WebPlugin.do("notification", data);
        }
      },
      showLoading: function (title = null, message = null, cancelCallback = true) {
        if (window.plugins && window.plugins.spinnerDialog) {
          if (_loadingTimer) clearTimeout(_loadingTimer);
          _loadingTimer = setTimeout(_ => {
            window.plugins.spinnerDialog.show(title, message, cancelCallback);
          }, 300);
        } else if (window.AppRoot) {
          window.AppRoot.showLoading(title);
        }
      },
      hideLoading: function () {
        if (window.plugins && window.plugins.spinnerDialog) {
          if (_loadingTimer) clearTimeout(_loadingTimer);
          window.plugins.spinnerDialog.hide();
        } else if (window.AppRoot) {
          window.AppRoot.hideLoading();
        }
      }
    }

    Vue.prototype.$random = function (min = 0, max = 100, ratio = 1) {
      let minValue = min * ratio;
      let maxValue = max * ratio;
      return (minValue + Math.round(Math.random() * (maxValue - minValue))) / ratio;
    };

    Vue.prototype.$auth = {
      user: {
        get: function () {
          return _user;
        },
        set: function (user) {
          _user = user;
        }
      },
      permission: function (names) {
        if (!names || !_user || !_user.roles || !_user.roles.length) return false;
        let valid = false;
        if (!_isArray(names)) names = [names];
        for (let i = 0, l = names.length; i < l; i++) {
          valid = _user.roles.includes(names[i]);
          if (valid) break;
        }
        return valid;
      }
    };

    Vue.prototype.$cookie = {
      set: function (name, value, num, interval) {
        /* eslint-disable no-new */
        let d = new Date()
        let base = 1000
        switch (interval) {
          case 'ms':
            base = 1
            break
          case 's':
            base = 1000
            break
          case 'm':
            base = 1000 * 60
            break
          case 'h':
            base = 1000 * 60 * 60
            break
          case 'd':
            base = 1000 * 60 * 60 * 24
            break;
        }

        interval || (interval = 's')
        num || (num = 1)
        d.setTime(d.getTime() + base * num)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
      },
      get: function (name) {
        let v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
      },
      delete: function (name) {
        this.set(name, '', -1)
      }
    }

    Vue.prototype.$uuid = function (connectChar) {
      connectChar = connectChar == null ? '-' : connectChar;
      let d = new Date().getTime();
      let _uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
      });
      return connectChar === '-' ? _uuid : _uuid.replace(/-/ig, connectChar);
    }

    Vue.prototype.$isArray = _isArray;

    Vue.prototype.$isPlainObject = function (o, strict = true) {
      let isObj = typeof o === 'object' && Object.prototype.toString.call(o).toLowerCase() === '[object object]' && !o.length
      if (strict && isObj) {
        let empty = true
        for (let p in o) {
          empty = !p
          break
        }
        isObj = !empty
      }
      return isObj
    }

    Vue.prototype.$isDate = function (o, strict = true) {
      let isdate = o instanceof Date
      if (isdate && strict) {
        isdate = !isNaN(o.getTime())
      }
      return isdate
    }

    Vue.prototype.$price = function (a, b, c, d, e = '￥') {
      let v = c
      if (typeof a === 'number') v = a
      const vstr = ((v || 0) / 100).toFixed(2)
      const vs = vstr.split('.')
      return e + vs[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + vs[1]
    }

    Vue.prototype.$simplePrice = function (a, b = "") {
      const vstr = ((a || 0) / 100).toFixed(2)
      const vs = vstr.split('.')
      return b + vs[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + vs[1]
    }

    Vue.prototype.$intPrice = function (a, b = "") {
      const vstr = ((a || 0) / 100).toFixed(2)
      const vs = vstr.split('.')
      return b + vs[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }

    Vue.prototype.$callPhone = function (phoneNumber) {
      if (typeof phoneNumber === "string" && /^\d+$/ig.test(phoneNumber)) {
        let a = document.createElement("a");
        a.href = `tel:${phoneNumber}`;
        a.click();
      }
    }

    Vue.prototype.$transform = function (el, prop = 'y') {
      let translates = document.defaultView.getComputedStyle(el, null).transform;
      let res = /^matrix\(([-\.\d]+), ([-\.\d]+), ([-\.\d]+), ([-\.\d]+), ([-\.\d]+), ([-\.\d]+)\)$/ig.exec(translates);
      if (res && res.length === 7) {
        return prop === "y" ? parseFloat(res[6]) : parseFloat(res[5]);
      } else {
        return 0;
      }
    }

    Vue.prototype.$routerLink = function (text, path, tooltip) {
      let able = false
      if (path && Vue.prototype.$isArray(Vue.prototype.$privilege) && Vue.prototype.$privilege.length > 0) {
        for (let p of Vue.prototype.$privilege) {
          let matchPath = p.path.replace(/(:(\w+))/i, (a, b, c) => {
            return p.param[c]
          })
          matchPath = '/' + matchPath
          if (matchPath === path) {
            able = true
          }
        }
      }

      let com = {}

      if (able) {
        com.template = tooltip ? `<Tooltip content="${tooltip}"><router-link :to="{path: '${path}'}">${text}</router-link></Tooltip>` : `<router-link :to="{path: '${path}'}">${text}</router-link>`
      } else {
        com.template = `<span>${text}</span>`
      }

      return com
    }

    Vue.prototype.$scriptLinker = function (url, id) {
      return new Promise((resolve, reject) => {
        let header = document.getElementsByTagName('head')[0];
        let prevent = false;
        if (id) {
          let script = header.querySelector(`#${id}`);
          if (script) {
            prevent = true;
            resolve();
          }
        }
        if (!prevent) {
          if (url) {
            let script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = url
            if (id) script.id = id
            if (script.readyState) {
              // ie
              script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                  script.onreadystatechange = null
                  resolve()
                }
              }
            } else {
              script.onload = () => {
                script.onload = null
                resolve()
              }
            }
            header.appendChild(script)
          } else {
            reject(new Error('URL params is required!'))
          }
        }
      })
    }

    Vue.prototype.$styleSheetLinker = function (url) {
      return new Promise((resolve, reject) => {
        if (url) {
          let link = document.createElement('link')
          link.type = 'text/css'
          link.rel = 'stylesheet'
          link.href = url
          document.getElementsByTagName('head')[0].appendChild(link)
        } else {
          reject(new Error('URL params is required!'))
        }
      })
    }

    Vue.prototype.$install = function (io, name) {
      if (io) {
        if (name) {
          Vue.use(io, { name: name })
        } else {
          Vue.use(io)
        }
      }
    }

    Vue.prototype.$SHA265 = function (s) {
      let chrsz = 8;
      let hexcase = 0;

      function safeAdd(x, y) {
        let lsw = (x & 0xFFFF) + (y & 0xFFFF);
        let msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
      }

      function S(X, n) {
        return (X >>> n) | (X << (32 - n));
      }

      function R(X, n) {
        return (X >>> n);
      }

      function Ch(x, y, z) {
        return ((x & y) ^ ((~x) & z));
      }

      function Maj(x, y, z) {
        return ((x & y) ^ (x & z) ^ (y & z));
      }

      function Sigma0256(x) {
        return (S(x, 2) ^ S(x, 13) ^ S(x, 22));
      }

      function Sigma1256(x) {
        return (S(x, 6) ^ S(x, 11) ^ S(x, 25));
      }

      function Gamma0256(x) {
        return (S(x, 7) ^ S(x, 18) ^ R(x, 3));
      }

      function Gamma1256(x) {
        return (S(x, 17) ^ S(x, 19) ^ R(x, 10));
      }

      function coreSha256(m, l) {
        let K = [0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2];
        let HASH = [0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19];
        /* eslint-disable no-new */
        let W = new Array(64);
        let a, b, c, d, e, f, g, h, i, j;
        let T1, T2;
        m[l >> 5] |= 0x80 << (24 - l % 32);
        m[((l + 64 >> 9) << 4) + 15] = l;
        for (i = 0; i < m.length; i += 16) {
          a = HASH[0];
          b = HASH[1];
          c = HASH[2];
          d = HASH[3];
          e = HASH[4];
          f = HASH[5];
          g = HASH[6];
          h = HASH[7];
          for (j = 0; j < 64; j++) {
            if (j < 16) W[j] = m[j + i];
            else W[j] = safeAdd(safeAdd(safeAdd(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
            T1 = safeAdd(safeAdd(safeAdd(safeAdd(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
            T2 = safeAdd(Sigma0256(a), Maj(a, b, c));
            h = g;
            g = f;
            f = e;
            e = safeAdd(d, T1);
            d = c;
            c = b;
            b = a;
            a = safeAdd(T1, T2);
          }
          HASH[0] = safeAdd(a, HASH[0]);
          HASH[1] = safeAdd(b, HASH[1]);
          HASH[2] = safeAdd(c, HASH[2]);
          HASH[3] = safeAdd(d, HASH[3]);
          HASH[4] = safeAdd(e, HASH[4]);
          HASH[5] = safeAdd(f, HASH[5]);
          HASH[6] = safeAdd(g, HASH[6]);
          HASH[7] = safeAdd(h, HASH[7]);
        }
        return HASH;
      }

      function str2binb(str) {
        let bin = [];
        let mask = (1 << chrsz) - 1;
        for (let i = 0; i < str.length * chrsz; i += chrsz) {
          bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
        }
        return bin;
      }

      function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n');
        let utftext = '';
        for (let n = 0; n < string.length; n++) {
          let c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      }

      function binb2hex(binarray) {
        let hexTab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef';
        let str = '';
        for (let i = 0; i < binarray.length * 4; i++) {
          str += hexTab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
            hexTab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
        }
        return str;
      }

      s = Utf8Encode(s);
      return binb2hex(coreSha256(str2binb(s), s.length * chrsz));
    }

    function _emoji_convert_unit(str) {
      var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
      str = str.replace(patt, function (char) {
        var H, L, code;
        if (char.length === 2) {
          H = char.charCodeAt(0); // 取出高位
          L = char.charCodeAt(1); // 取出低位
          code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
          return "&#" + code + ";";
        } else {
          return char;
        }
      });
      return str;
    }

    function _emoji_converter(d) {
      if (Vue.prototype.$isPlainObject(d)) {
        for (let k in d) {
          if (d.hasOwnProperty(k)) {
            let sd = d[k];
            if (typeof sd === 'string' && sd !== '') {
              d[k] = _emoji_convert_unit(sd);
            } else {
              _emoji_converter(sd);
            }
          }
        }
      }
    }

    Vue.prototype.$emojiConvert = function (d) {
      _emoji_converter(d);
    }
  }
}