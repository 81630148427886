<template>
  <div
    class="app-dashboard-analy app-dashboard-analy-camera h"
    :class="{ modal: visible }"
  >
    <transition
      enter-active-class="animated faster fadeInLeft"
      leave-active-class="animated faster fadeOutLeft"
    >
      <div class="page-title" style="width: 28%" v-if="park && visible">
        <div class="flex _text">{{ park.name }}</div>
        <div class="_close el-icon-close no-flex" @click="doClose"></div>
      </div>
    </transition>
    <transition
      enter-active-class="animated faster fadeInLeft"
      leave-active-class="animated faster fadeOutLeft"
    >
      <div class="panel v no-flex" style="width: 28%" v-if="visible">
        <panel title="基本统计信息" class="no-flex">
          <div>
            <div class="h">
              <number-view
                :value="park.originInfo.carbitnum"
                title="车位数"
                icon="fa-solid fa-parking"
                style="width: 30%"
              />
              <number-view
                :value="
                  Math.round(
                    (park.originInfo.carbitnum - park.originInfo.spacebitnum) *
                      0.8
                  )
                "
                title="月租车"
                icon="fa-solid fa-car-side"
                style="width: 30%"
              />
              <number-view
                :value="0"
                :decimals="1"
                prefix="￥"
                title="平均费用"
                icon="fa-solid fa-yen-sign"
                class="flex"
              />
            </div>
            <div class="h">
              <number-view
                :value="park.originInfo.spacebitnum"
                title="空位数"
                icon="fa-regular fa-flag"
                style="width: 30%"
              />
              <number-view
                :value="
                  park.originInfo.carbitnum -
                  park.originInfo.spacebitnum -
                  Math.round(
                    (park.originInfo.carbitnum - park.originInfo.spacebitnum) *
                      0.8
                  )
                "
                title="临时车"
                icon="fa-solid fa-taxi"
                style="width: 30%"
              />
              <number-view
                :value="Math.round(Math.random() * 2 + 2) / 10"
                :decimals="1"
                suffix="h"
                title="平均时长"
                icon="fa-solid fa-clock"
                class="flex"
              />
            </div>
          </div>
        </panel>

        <panel title="进、离场次数统计" class="flex">
          <parking-time />
        </panel>
      </div>
    </transition>

    <transition
      enter-active-class="animated faster fadeInDown"
      leave-active-class="animated faster fadeOutUp"
    >
      <div class="panel sa-r v" style="height: 50%; width: 47%" v-if="visible">
        <panel title="停车场使用率分析" class="flex">
          <parking-use />
        </panel>
      </div>
    </transition>

    <transition
      enter-active-class="animated faster fadeInRight"
      leave-active-class="animated faster fadeOutRight"
    >
      <div class="panel v no-flex" style="width: 25%" v-if="visible">
        <panel title="停车场进出记录"  class="v c flex">
          <div v-if="infoList.length > 0" class="park" style="font-size: 13px">
            <div
              class="roll-item"
              v-for="(item, index) in infoList"
              :key="index"
            >
              <div>
                <span class="_title">车牌号&emsp;</span>
                {{ item.plate_number }}
              </div>
              <div>
                <span class="_title">进场时间</span>
                {{ item.entrance_time }}
              </div>
              <div>
                <span class="_title">是否离场</span>
                {{ item.is_exit ? "否" : "是" }}
              </div>
              <div>
                <span class="_title">离场时间</span>
                {{ item.exit_time }}
              </div>
            </div>
          </div>
          <div v-else>暂无停车场进出记录</div>
        </panel>
        <panel title="监控视频" class="no-flex">
          <div class="h sa wrap">
            <div class="camera-item no-flex" v-for="c in cameras" :key="c.id">
              <div class="_title"></div>
              <camera-video
                :code="c.cameraIndexCode"
                :width="200"
                :height="120"
              />
            </div>
          </div>
        </panel>
      </div>
    </transition>
  </div>
</template>

<script>
import ParkingTime from "./charts/parking-time";
import ParkingUse from "./charts/parking-use";
import { getInOrOutRecord } from "@/api/data";
import { getPreview } from "@/api/data";
export default {
  data() {
    return {
      cameras: [],
      cameraLoaded: false,
      infoList: [],
      classOption: {
        step: 0.85, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000,
      },
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getInOrOut();
          this.loadCameras();
        } else {
          console.log("1");
        }
      },
    },
    "park.id": {
      handler(val, old) {
        if (this.visible) {
          this.getInOrOut();
        }
      },
    },
  },
  methods: {
    loadCameras() {
      if (!this.cameraLoaded && this.visible) {
        getPreview({ type: 5, page: 0, size: 4 }).then((res) => {
          if (res && res.content) {
            res.content.sort((a, b) => {
              return a.cameraName > b.cameraName ? 1 : -1;
            });
            this.cameras = res.content;
            this.cameraLoaded = true;
          }
        });
      }
    },
    getInOrOut() {
      getInOrOutRecord({
        park_id: this.park && this.park.id,
        page: 0,
        size: 50,
        sort: "sortTime,desc",
      }).then((res) => {
        this.infoList = res.content.splice(0, 6);
      });
    },
    doClose() {
      this.$emit("close");
    },
  },
  components: {
    ParkingTime,
    ParkingUse,
  },
  props: {
    visible: Boolean,
    park: Object,
  },
};
</script>
<style lang="scss" scoped>
.panel {
  .park {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
  }
}
</style>