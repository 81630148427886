import axios from "axios"
import { getToken } from '@/utils/auth'
import qs from "qs"
import { removeToken } from "@/utils/auth";
import router from "@/router"

let canceller = {};

const request = axios.create({
    withCredentials: true,
    timeout: 60000,
    baseURL: config.baseURL
});

request.interceptors.request.use(
    config => {
        if (config._uToken !== false) {
            let token = getToken();
            if (token) {
                config.headers["Authorization"] = token;
            }
        } else {
            config.withCredentials = false;
        }
        if (config.cancellerId) {
            if (canceller[config.cancellerId]) canceller[config.cancellerId].cancel();
            canceller[config.cancellerId] = axios.CancelToken.source();
            config.cancelToken = canceller[config.cancellerId].token;
        }
        config.headers['Content-Type'] = 'application/json'
        if (typeof config.data === "string") {
            config.headers["Content-Type"] = "text/plain";
        }
        if (config.method === "get" && config.params instanceof Object) {
            delete config._uToken;
            for (let k in config.params) {
                if (config.params.hasOwnProperty(k) && (config.params[k] === "" || config.params[k] === null || config.params[k] === undefined)) {
                    delete config.params[k];
                }
            }
            config.url += (config.url.indexOf("?") < 0 ? "?" : "&") + qs.stringify(config.params, { indices: false });
            delete config.params;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    response => {
        if (response.config && response.config.cancellerId) {
            delete canceller[config.cancellerId];
        }
        return Promise.resolve(response.data);
    },
    error => {
        console.log("dsadasdasd", error);
        if (axios.isCancel(error)) {
            return false;
        } else if (error.message === 'Network Error') {
            return Promise.reject(new Error("无法连接到服务器，请稍候重试。"));
        } else if (error.code === 'ECONNABORTED' && /timeout/ig.test(error.message)) {
            return Promise.reject(new Error("请求超时了，请稍候重试。"));
        } else {
            if (error.response && error.response.status === 401) {
                removeToken()
                router.push('/')
                return Promise.reject({ message: "Authorization error." });
            } else {
                let rd = error.response && error.response.data;
                let data = {};
                data.message = rd.msg || rd.message || rd.MessageDetail || rd.Message;
                data.status = error.response.status;
                data.origin = rd;
                return Promise.reject(data);
            }
        }
    }
);

export default request