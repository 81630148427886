import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard/index'
import Login from '../views/dashboard/login'
import {
  getToken
} from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: '大屏',
    }
  },
]

const router = new VueRouter({
  routes
})
const whiteList = ['/login'] 
router.beforeEach((to, from, next) => {
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/dashboard'
      })
    } else {
      next()
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/`) // 否则全部重定向到登录页
    }
  }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
