<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    init() {
      let chart = echarts.init(this.$el, "owner");
      var option = {
        grid: {
          top: 40,
          right: 0,
          bottom: 20,
          left: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{c0}个",
        },
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [17, 18, 9, 14, 11, 10, 8, 16, 18, 14, 8, 7],
            type: "bar",
            barMaxWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                  formatter: "{c0}个",
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#e5764a33" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#e5764a33" },
                ]),
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
};
</script>