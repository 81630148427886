<template>
  <div>
    <div class="padding-20-0">
      时间：2022-02-22 12:20:36
      <br />
      设备名称：{{ data.name }}
      <br />地址：阿瓦提县团结东路98号三号井盖
      <br />设备ID：JG001
      <br />报警信息：正常
      <br />区域负责人：张伟
      <br />联系方式：13152561159
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
};
</script>