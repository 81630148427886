<template>
  <div
    class="app-dashboard-analy app-dashboard-overview h sb"
    :class="{ modal: visible }"
  >
    <!-- <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
    >
      <city-overview class="overview" v-if="visible" />
    </transition>-->
    <transition
      enter-active-class="animated faster fadeInLeft"
      leave-active-class="animated faster fadeOutLeft"
    >
      <div class="panel v no-flex" style="width: 25%" v-if="visible">
        <panel title="市政设施" class="no-flex">
          <div class="h m wrap">
            <number-view
              v-for="c in counts"
              :key="c.id"
              :value="c.count"
              :title="c.name"
              :icon="c.icon"
              style="width: 40%"
              class="flex"
            />
          </div>
        </panel>
        <panel class="flex" title="保障性安居工程任务情况">
          <rental-housing />
        </panel>
        <!-- <panel title="全区经济概况" style="height: 33%;">
          <economy-view class="_content" />
        </panel>
        <panel title="人口分析" style="height: 33%;">
          <population-view />
        </panel>
        <panel title="城市基础建设" class="flex">
          <city-view />
        </panel>-->
        <!-- <e-title title="人口分析" />
        <population-view style="height: 200px" />
        <e-title title="城市基础建设" />
        <city-view class="flex" />-->
      </div>
    </transition>

    <transition
      enter-active-class="animated faster fadeInDown"
      leave-active-class="animated faster fadeOutUp"
    >
      <div class="panel flex v" v-if="visible">
        <panel title="阿瓦提示意图" class="no-flex">
          <div class="planning-switcher" slot="title">
            <el-radio-group v-model="showPlanningDetail" size="mini">
              <el-radio-button :label="false">地图</el-radio-button>
              <el-radio-button :label="true">明细</el-radio-button>
            </el-radio-group>
          </div>
          <div class="planning" style="margin: 0 15px 15px 15px">
            <div
              ref="map"
              style="width: 100%; height: 40vh; overflow: hidden"
            ></div>
            <div class="planning-detail" v-show="showPlanningDetail">
              <ol>
                <span class="_title">棚户区改造及公租房</span>
                <li>
                  2022年计划投资25000万元，新建棚户区安置房1000套及配套附属。
                </li>
                <li>2022年计划投资7500万元，新建公租房420套及配套附属。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟整合小区</span>
                <li>
                  2022年计划投资12500万元，将全县40个小区整合为7个大小区。
                </li>
                <li>2022年计划投资3500万元，新建保障性住房238套。</li>
                <li>2022年投资2000万，打造老旧小区示范观摩点1个。</li>
                <li>2022年投资1800万，打造老旧小区整合示范小区1个。</li>
              </ol>
              <ol>
                <span class="_title">2022年计划征收片区</span>
                <li>2022年计划征收8个片区，征收面积：1957.42亩。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟建城市驿站</span>
                <li>2022年计划投路480万元，新建城市驿站8座。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟建非机动车生态停车场</span>
                <li>2022年拟投资100万元，新建非机动车生态停车场1个。</li>
              </ol>
              <ol>
                <span class="_title">2022年绿化提升改造区域</span>
                <li>
                  2022年计划投资6000万元完成现有公园、广场、街头游园绿化带灌木种植及配套附属建设。
                </li>
              </ol>
              <ol>
                <span class="_title">2022年拟建街头绿地</span>
                <li>2022年计划投资280万元，新建街头绿地15个，面积：9444㎡。</li>
              </ol>
              <ol>
                <span class="_title">友好社区环境提升整治</span>
                <li>2022年完成友好社区道路、供排水管网等基础设施建设。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟建红绿灯</span>
                <li>2022年计划投资600万元，更换20套老旧红绿灯。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟建道路</span>
                <li>
                  2022年计划投资2.30亿，新（改扩）建道路8条，全长：12.3公里
                </li>
              </ol>
              <ol>
                <span class="_title">2021年在建道路</span>
                <li>2022年完成3条在建道路的绿化、亮化等附属设施建设。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟建排水防涝管网</span>
                <li>2022年计划投资1500万元，新建排水防涝管网1公里。</li>
              </ol>
              <ol>
                <span class="_title">2022年新建供水管网</span>
                <li>2022年投资900万元，新建供水管网4公里。</li>
              </ol>
              <ol>
                <span class="_title">2022年拟设立大物委服务站</span>
                <li>2022年计划成立大物委服务站7个。</li>
              </ol>
            </div>
          </div>
        </panel>
        <div class="flex h" style="overflow: hidden">
          <panel title="环境监测数据" class="flex">
            <!-- <div class="h env">
              <div class="_col no-flex _title v sb">
                <div class="_cell">监测项目</div>
                <div class="_cell">污水排放量</div>
                <div class="_cell">污水处理量</div>
                <div class="_cell">污水处理率</div>
                <div class="_cell">PM2.5平均浓度</div>
                <div class="_cell">PM10平均浓度</div>
              </div>
              <div v-for="d in envs" :key="d.year" class="_col v flex sb">
                <div class="_cell">{{d.year}}年</div>
                <div class="_cell">
                  <count-to :end-val="d.wtotal" class="_val"></count-to>
                  <span class="_unit">万立方米</span>
                </div>
                <div class="_cell">
                  <count-to :end-val="d.wbtotal" class="_val"></count-to>
                  <span class="_unit">万立方米</span>
                </div>
                <div class="_cell">
                  <count-to :end-val="d.wpercent" :decimals="2" class="_val"></count-to>
                  <span class="_unit">%</span>
                </div>
                <div class="_cell">
                  <count-to :end-val="d.pm25" class="_val"></count-to>
                  <span class="_unit">μg/m³</span>
                </div>
                <div class="_cell">
                  <count-to :end-val="d.pm10" class="_val"></count-to>
                  <span class="_unit">μg/m³</span>
                </div>
              </div>
            </div>-->
            <environment></environment>
          </panel>
          <panel title="预警预告" style="width: 50%">
            <seamless-scroll :data="alarms" :class-option="classOption">
              <div
                class="roll-item"
                v-for="(item, index) in alarms"
                :key="index"
              >
                <div>标题：{{ item.title}}</div>

                <div>内容：{{ item.content.replace(/<[^>]+>/g, "") }}</div>
                <!-- <div class="fs-mini">{{ item.year }}</div> -->
              </div>
            </seamless-scroll>
          </panel>
        </div>
      </div>
    </transition>

    <!-- <transition
      enter-active-class="animated faster fadeInDown"
      leave-active-class="animated faster fadeOutUp"
    >
      <div class="counts sa-l h c sa no-flex" v-if="visible">
        <number-view
          :value="villageCount"
          title="小区数量"
          icon="las la-city"
          style="width: 120px"
        />
        <number-view
          :value="totleMeasure"
          title="小区总面积"
          icon="las la-users"
          style="width: 180px"
        />
        <number-view
          :value="totleFamilyNumber"
          title="总户数"
          icon="las la-exclamation-triangle"
          style="width: 120px"
        />
      </div>
    </transition>-->
    <!-- <transition enter-active-class="animated faster fadeInRight" leave-active-class="animated faster fadeOutRight">
      <div class="panel v flex" v-if="visible">
        <panel title="预警预告" style="height: 50%;">
          <seamless-scroll :data="notices" :class-option="classOption">
            <div class="roll-item" v-for="(item, index) in notices" :key="index">
              <div>{{ item.content }}</div>
              <div class="fs-mini">{{ item.year }}</div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </transition>-->

    <transition
      enter-active-class="animated faster fadeInRight"
      leave-active-class="animated faster fadeOutRight"
    >
      <div class="panel v no-flex" style="width: 25%" v-if="visible">
        <panel title="通知公告" style="height: 50%">
          <seamless-scroll :data="notices" :class-option="classOption">
            <div
              class="roll-item"
              v-for="(item, index) in notices"
              :key="index"
            >
              <div>时间：{{ item.createTime }}</div>
              <div>标题：{{ item.title }}</div>
              <div>类型：{{ item.typeName }}</div>
            </div>
          </seamless-scroll>
        </panel>

        <panel title="老旧小区改造" class="flex">
          <community-reform />
        </panel>
        <!-- <panel title="执法投诉及处理信息" style="height: 50%;">
          <seamless-scroll :data="complaintData2" :class-option="classOption">
            <div class="roll-item" v-for="(item, index) in complaintData2" :key="index">
              <div>{{ item.content }}</div>
              <div class="fs-mini">{{ item.year }}</div>
            </div>
          </seamless-scroll>
        </panel>
        <panel title="其他投诉及处理信息" class="flex">
          <seamless-scroll :data="complaintData" :class-option="classOption">
            <div class="roll-item" v-for="(item, index) in complaintData" :key="index">
              <div>{{ item.content }}</div>
              <div class="fs-mini">{{ item.year }}</div>
            </div>
          </seamless-scroll>
        </panel>-->
      </div>
    </transition>
  </div>
</template>

<script>
// import EconomyView from "./charts/economy-view";
// import PopulationView from "./charts/population-view";
// import CityView from "./charts/city-view";

// import ComplaintTrend from "./charts/complaint-trend";
// import SuperCommunity from "./charts/super-community";
// import CommunityAge from "./charts/community-age";
// import CityOverview from "./charts/city-overview";
// import PropertySatisfaction from "./charts/property-satisfaction";
// import ComplaintTypeDistribution from "./charts/complaint-type-distribution";
// import RepairTrend from "./charts/repair-trend";

import RentalHousing from "./charts/rental-housing";
import CommunityReform from "./charts/community-reform";
import Environment from "./charts/environment";
import CountTo from "vue-count-to";
import { getNoticeList, getWarnList } from "@/api/data";

export default {
  components: {
    RentalHousing,
    CommunityReform,
    CountTo,
    Environment,
  },
  data() {
    return {
      notices: [
        // {
        //   id: 1,
        //   content: "国家税务总局阿瓦提县税务局2021年3月非正常户公告",
        //   year: "2022-5-3 12:35",
        // },
        // {
        //   id: 2,
        //   content: "关于对阿瓦提县货物运输车辆超限超载源头治理企业的公示",
        //   year: "2022-5-3 16:09",
        // },
        // {
        //   id: 3,
        //   content: "地区举行2022年公路建设项目开（复）工仪式",
        //   year: "2022-5-8 10:41",
        // },
        // {
        //   id: 4,
        //   content: "地区17个乡镇（街道）被命名“自治区优秀平安乡镇（街道）",
        //   year: "2022-5-8 12:08",
        // },
        // {
        //   id: 5,
        //   content: "2022年阿克苏地区新兵征集公告",
        //   year: "2022-5-8 15:10",
        // },
        // {
        //   id: 6,
        //   content: "国家税务总局阿瓦提县税务局2021年2月非正常户公告",
        //   year: "2022-5-10 12:35",
        // },
        // {
        //   id: 7,
        //   content:
        //     "关于公布阿瓦提县校外违规开展学科类培训投诉举报电话和邮箱的公告",
        //   year: "2022-5-10 14:58",
        // },
        // {
        //   id: 8,
        //   content: "关于严厉打击哄抬物价、销售假冒伪劣商品等违法行为的通告",
        //   year: "2022-5-10 18:26",
        // },
      ],
      alarms: [
        // {
        //   id: 1,
        //   content:
        //     "市民反映：位于北京中路23号人行道，井盖破损严重，影响过往行人安全，请及时维修。",
        //   year: "2021-10-14 8:50",
        // },
        // {
        //   id: 2,
        //   content:
        //     "小区居民反映：锦绣花园小区居民向城管反映，有小区居民未按规定使用狗绳，对本小区居民造成安全威胁，请及时解决问题。",
        //   year: "2021-7-4 11:40",
        // },
        // {
        //   id: 3,
        //   content:
        //     "市民反映：位于县人民公园西南位置，有人为破坏公共设施，请及时处理。",
        //   year: "2020-10-21 16:33",
        // },
        // {
        //   id: 4,
        //   content:
        //     "市民反映：位于发展中路34号位置，有人乱扔乱倒垃圾，请有关部门及时处理。",
        //   year: "2020-5-14 8:50",
        // },
        // {
        //   id: 5,
        //   content:
        //     "小区居民反映：位于小吃一条街，烧烤烟太大，严重影响居民的正常生活，请有关部门出面解决此问题。",
        //   year: "2020-3-4 11:40",
        // },
      ],
      classOption: {
        step: 0.65,
        limitMoveNum: 1,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 3000,
      },
      showPlanningDetail: false,
      envs: [
        {
          year: "2020",
          wtotal: 300,
          wbtotal: 300,
          wd: 1.5,
          wpercent: 93.75,
          pm25: 84,
          pm10: 197,
        },
        {
          year: "2021",
          wtotal: 390,
          wbtotal: 390,
          wd: 1.5,
          wpercent: 100,
          pm25: 52,
          pm10: 183,
        },
      ],
      timer: null,
    };
  },
  props: {
    visible: Boolean,
    counts: Object,
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.timer = setInterval(this.doWarnListener, 20000);
          this.doWarnListener();
          this.$nextTick(() => {
            this.drawMap();
          });
        } else {
          this.stopWarnListener();
        }
      },
    },
  },
  methods: {
    doWarnListener() {
      Promise.all([
        getNoticeList({ page: 0, size: 10, sort: "createTime,desc" }),
        getWarnList({ page: 0, size: 10, sort: "createTime,desc" }),
      ]).then((res) => {
        this.notices = res[0].content || [];
        this.alarms = res[1].content || [];
      });
    },
    stopWarnListener() {
      if (this.timer) clearInterval(this.timer);
    },
    doViewImage(e) {
      let el = e.target;
      if (el.requestFullscreen) {
        return el.requestFullscreen();
      } else if (el.webkitRequestFullScreen) {
        return el.webkitRequestFullScreen();
      } else if (el.mozRequestFullScreen) {
        return el.mozRequestFullScreen();
      } else {
        return el.msRequestFullscreen();
      }
    },
    drawMap() {
      let chart = echarts.init(this.$refs.map);
      let lines = [
        {
          coords: [
            [80.386348, 40.640319],
            [80.384803, 40.644151],
            [80.380689, 40.650597],
            [80.374329, 40.649146],
          ],
        },
        {
          coords: [
            [80.392097, 40.652951],
            [80.387211, 40.652294],
            [80.386384, 40.65187],
            [80.37458, 40.649201],
          ],
        },
        {
          coords: [
            [80.359058, 40.652499],
            [80.367071, 40.652007],
            [80.370215, 40.645055],
            [80.374706, 40.646108],
            [80.373556, 40.648969],
            [80.37458, 40.649229],
          ],
        },
        {
          coords: [
            [80.386276, 40.640347],
            [80.384839, 40.644124],
            [80.378641, 40.642536],
            [80.375389, 40.644507],
            [80.37467, 40.646149],
            [80.370197, 40.645055],
            [80.366855, 40.644589],
          ],
        },
        {
          coords: [
            [80.398745, 40.643358],
            [80.394882, 40.646423],
            [80.388935, 40.645096],
            [80.386546, 40.648421],
            [80.38306, 40.64693],
            [80.380671, 40.65057],
            [80.378623, 40.655893],
            [80.385935, 40.656878],
          ],
        },
        {
          coords: [
            [80.35886, 40.657822],
            [80.365831, 40.655195],
            [80.371903, 40.653005],
            [80.374562, 40.646081],
            [80.370178, 40.645061],
            [80.373286, 40.638547],
          ],
        },
        {
          coords: [
            [80.364932, 40.660606],
            [80.376323, 40.654271],
            [80.378281, 40.650056],
            [80.384569, 40.651439],
            [80.388773, 40.645061],
            [80.384803, 40.644062],
            [80.386995, 40.638622],
            [80.386941, 40.63097],
          ],
        },
        {
          coords: [
            [80.384264, 40.661406],
            [80.38721, 40.652321],
            [80.394756, 40.646382],
            [80.394002, 40.646163],
            [80.400146, 40.641318],
            [80.391953, 40.636309],
            [80.390265, 40.637869],
          ],
        },
        {
          coords: [
            [80.396481, 40.664143],
            [80.399356, 40.653799],
            [80.400362, 40.647695],
            [80.388827, 40.645041],
            [80.390193, 40.641291],
            [80.382467, 40.639374],
            [80.376718, 40.65347],
            [80.361842, 40.651363],
          ],
        },
        {
          coords: [
            [80.406829, 40.654893],
            [80.399391, 40.653853],
            [80.400326, 40.647668],
            [80.396167, 40.646724],
            [80.39464, 40.653251],
            [80.387202, 40.652307],
            [80.386842, 40.653415],
            [80.386124, 40.653402],
            [80.382872, 40.654483],
            [80.382872, 40.654483],
            [80.382243, 40.656398],
            [80.378578, 40.655906],
            [80.379297, 40.653942],
            [80.367008, 40.652027],
            [80.367008, 40.652027],
          ],
        },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
        // {
        //   coords: [[], [], [], [], [], [], [], [], []],
        // },
      ];
      let hStep = 300 / (lines.length - 1);
      lines.forEach((l, idx) => {
        l.lineStyle = {
          normal: {
            color: echarts.color.modifyHSL("#5A94DF", Math.round(hStep * idx)),
          },
        };
      });

      let option = {
        bmap: {
          center: [80.382155, 40.649907],
          zoom: 15.5,
          roam: true,
          mapStyle: {
            styleJson: [
              {
                featureType: "water",
                elementType: "all",
                stylers: {
                  color: "#031628",
                },
              },
              {
                featureType: "land",
                elementType: "geometry",
                stylers: {
                  color: "#000032",
                },
              },
              {
                featureType: "highway",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "arterial",
                elementType: "geometry.fill",
                stylers: {
                  color: "#002e5e",
                },
              },
              {
                featureType: "arterial",
                elementType: "geometry.stroke",
                stylers: {
                  color: "#002e5e",
                },
              },
              {
                featureType: "local",
                elementType: "geometry",
                stylers: {
                  color: "#002e5e",
                },
              },
              {
                featureType: "railway",
                elementType: "geometry.fill",
                stylers: {
                  color: "#000000",
                },
              },
              {
                featureType: "railway",
                elementType: "geometry.stroke",
                stylers: {
                  color: "#08304b",
                },
              },
              {
                featureType: "subway",
                elementType: "geometry",
                stylers: {
                  lightness: -70,
                },
              },
              {
                featureType: "building",
                elementType: "geometry.fill",
                stylers: {
                  color: "#000000",
                },
              },
              {
                featureType: "all",
                elementType: "labels.text.fill",
                stylers: {
                  color: "#857f7f",
                },
              },
              {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: {
                  color: "#000000",
                },
              },
              {
                featureType: "building",
                elementType: "geometry",
                stylers: {
                  color: "#022338",
                },
              },
              {
                featureType: "green",
                elementType: "geometry",
                stylers: {
                  color: "#062032",
                },
              },
              {
                featureType: "boundary",
                elementType: "all",
                stylers: {
                  color: "#465b6c",
                },
              },
              {
                featureType: "manmade",
                elementType: "all",
                stylers: {
                  color: "#022338",
                },
              },
              {
                featureType: "label",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
            ],
          },
        },
        series: [
          {
            type: "lines",
            coordinateSystem: "bmap",
            polyline: true,
            data: lines,
            lineStyle: {
              width: 0,
            },
            effect: {
              constantSpeed: 150,
              show: true,
              trailLength: 0.6,
              symbolSize: 4,
            },
            zlevel: 1,
          },
        ],
      };
      chart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.app-dashboard-overview {
  .env {
    > ._col {
      > ._cell {
        padding: 0 20px;
        white-space: nowrap;
        line-height: 32px;
        &:first-child {
          border-bottom: #2e90f533 solid 1px;
          color: #78eefc;
          font-weight: bold;
        }

        > ._val {
          font-weight: bold;
          font-size: 20px;
        }

        > ._unit {
          margin-left: 5px;
          opacity: 0.6;
          // float: right;
          font-size: 14px;
        }
      }

      &._title {
        text-align: right;
        color: #78eefc;
      }
    }
  }

  .planning {
    position: relative;

    &-detail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000032ee;
      // font-size: 0.9rem;
      line-height: 2;
      overflow: hidden auto;
      ._title {
        font-weight: bold;
        color: #78eefc;
      }
    }

    &-switcher {
      position: absolute;
      top: 5px;
      right: 15px;
      .el-radio-button--mini .el-radio-button__inner {
        padding: 5px 15px;
      }
    }

    .BMap_cpyCtrl {
      display: none;
    }
  }
}
</style>