<template>
  <div>
    <div id="cmsv6flash" class="playWindow"></div>
    <div class="operate">
      <div class="play" @click="playVideo()">播放</div>
      <div class="play" @click="stopVideo()">暂停</div>
      <div class="play" @click="startTalkback()" style="width: 90px">
        开始对讲
      </div>
      <div class="play" @click="stopTalkback()" style="width: 90px">
        停止对讲
      </div>
    </div>

    <div class="padding-20-0">所在位置：{{ data.address }}</div>
  </div>
</template>

<script>
import { getauth } from "@/api/data";

export default {
  data() {
    return {
      isInitFinished: false,
      showWinNum: 1,
      authJsession: null,
      devIdno: null,
      chnNum: null,
      serverIp: null,
      serverPort: null,
    };
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  methods: {
    initAuth() {
      getauth().then((res) => {
        this.authJsession = res.jsession;
        if (this.authJsession) {
          this.initPlayer();
        }
      });
    },
    loadParams() {
      //设备号
      this.devIdno = this.data.id;
      //指定第几通道
      this.chnNum = 0;
      //设置视频插件的服务器ip
      this.serverIp = config.serverIp;
      //登录服务器端口
      this.serverPort = config.serverHost;
    },
    initPlayer() {
      this.loadParams();
      //视频插件初始化参数
      var params = {
        lang: "zh-cn", //"en", "zh-cn", "zh-tw"
      };
      //视频插件类型 flash、h5、auto。
      //如果不需要调用 ttxVideoAll.switchType 方法，建议明确 指定 flash或者h5
      var playType = "auto";

      var width = 440;
      var height = 300;
      ttxVideoAll.init("cmsv6flash", width, height, params, playType);
      this.initFlash();
    },
    initFlash() {
      if (
        typeof swfobject == "undefined" ||
        swfobject.getObjectById("cmsv6flash") == null ||
        typeof swfobject.getObjectById("cmsv6flash").setWindowNum == "undefined"
      ) {
        setTimeout(initFlash, 50);
      } else {
        //设置视频插件的语言('playerType=flash'时生效)
        swfobject.getObjectById("cmsv6flash").setLanguage("cn.xml");
        //先将全部窗口创建好
        swfobject.getObjectById("cmsv6flash").setWindowNum(36);
        //再配置当前显示的窗口数目
        swfobject.getObjectById("cmsv6flash").setWindowNum(this.showWinNum);
        //设置视频插件的服务器ip和登录服务器端口
        swfobject
          .getObjectById("cmsv6flash")
          .setServerInfo(this.serverIp, this.serverPort);
        //插件初始化完成
        this.isInitFinished = true;
      }
    },
    //开始预览视频
    playVideo() {
      this.loadParams();
      //视频插件初始化完成
      if (this.isInitFinished) {
        //停止播放视频
        swfobject.getObjectById("cmsv6flash").stopVideo(0);
        //设置视频窗口标题
        swfobject.getObjectById("cmsv6flash").setVideoInfo(0, "vehicle1-CH1");
        //指定窗口下标
        var winIndex = 0;
        //播放视频
        swfobject
          .getObjectById("cmsv6flash")
          .startVideo(
            winIndex,
            this.authJsession,
            this.devIdno,
            this.chnNum,
            1,
            true
          );
      } else {
        alert("插件初始化未完成");
      }
    },
    //停止播放视频
    stopVideo() {
      swfobject.getObjectById("cmsv6flash").stopVideo(this.chnNum);
    },
    //视频插件要初始化完成后才能调用
    //开始对讲
    startTalkback() {
      this.loadParams();
      //开始对讲
      swfobject
        .getObjectById("cmsv6flash")
        .startTalkback(
          this.authJsession,
          this.devIdno,
          this.chnNum,
          this.serverIp,
          this.serverPort
        );
      //返回 0成功，1表示正在对讲，2表示没有mic，3表示禁用了mic。
    },
    /**
     * cmsv6player 统一消息回调
     */
    onTtxVideoMsg(index, type) {
      console.log(type);
      if (type == "playRecive") {
        alert("开启对讲");
      }
      if (type == "stopTalk") {
        alert("停止对讲");
      }
      if (type === "showDownLoadDialog") {
        alert("需要下载对讲工具");
        downPcmTool();
      }
    },
    downPcmTool() {
      window.open("/product/CMSV6PcmTool/CMSV6PcmTool.exe");
    },
    //停止对讲
    stopTalkback() {
      swfobject.getObjectById("cmsv6flash").stopTalkback();
    },
  },
};
</script>

<style lang='scss'>
.operate{
 display: flex;
 .play{
   background-color: white;
   color: black;
   padding: 0 10px;
   margin-right: 5px;
   text-align: center;
   
 }
}
</style>