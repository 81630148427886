import request from '@/utils/request'

//根据id查询详情
export function getEquipmentList() {
  return request({
    url: 'api/equipment/list',
    method: 'get',
  })
}

//获取小区列表
export function getVillageList(params) {
  return request({
    url: 'api/manage/village/list',
    method: 'get',
    params
  })
}
//获取超大小区分析
export function getBigVillage() {
  return request({
    url: 'api/dashboard/village/bigVillage/0',
    method: 'get',
  })
}
//获取年份分析
export function getYearVillage() {
  return request({
    url: 'api/dashboard/village/age',
    method: 'get',
  })
}
//获取投诉走势分析
export function getReportLine() {
  return request({
    url: 'api/dashboard/complaint/line',
    method: 'get',
  })
}

//获取投诉类型分析
export function getReportType() {
  return request({
    url: 'api/dashboard/complaint/type',
    method: 'get',
  })
}
//获取维修走势分析
export function getRepairLine() {
  return request({
    url: 'api/dashboard/repair/line',
    method: 'get',
  })
}

//获取满意度分析
export function getEstateTop() {
  return request({
    url: 'api/dashboard/estate/top',
    method: 'get',
  })
}

//获取概况分析
export function getsummary() {
  return request({
    url: 'api/dashboard/village/summary',
    method: 'get',
  })
}

//获取摄像头地址
export function getCameraPlayUrl(code) {
  return request({
    url: 'api/camera/preview/'+code,
    method: 'get',
  })
}

//获取摄像头列表
export function getPreview(params) {
  return request({
    url: 'api/camera',
    method: 'get',
    params
  })
}

//获取摄像头列表
export function getPreviewList(params) {
  return request({
    url: 'api/camera/list',
    method: 'get',
    params
  })
}

//获取停车场列表
export function getParkList() {
  return request({
    url: 'api/dashboard/park',
    method: 'get',
  })
}

//获取停车场车位信息
export function getParkingLot(id) {
  return request({
    url: 'api/dashboard/parkLot?park_id='+id,
    method: 'get',
  })
}

//获取停车场进出场记录
export function getInOrOutRecord(params) {
  return request({
    url: 'api/sParkingRecord',
    method: 'get',
    params
  })
}


//获取jession信息
export function getauth() {
  return request({
    url: '/api/open/auth',
    method: 'get',
  })
}

//转发第三方接口
export function getApi(data){
  return request({
    url: '/api/open/common/get',
    method: 'post',
    data
  })
}
//小区公告
export function getNews() {
  return request({
    url: '/api/manage/news',
    method: 'get',
  })
}
//物业公司详情
export function getEstateById(id) {
  return request({
    url: '/api/manage/estate/getById/'+id,
    method: 'get',

  })
}
export function getWeather(data) {
  return request({
    url: 'api/weather',
    method: "post",
    data,
  })
}

//获取预警预告
export function getWarnList(params) {
  return request({
    url: '/api/earlyInfo',
    method: 'get',
    params
  })
}
//获取公告列表不分类型
export function getNoticeList(params) {
  return request({
    url: '/api/manage/news',
    method: 'get',
    params
  })
}
//获取投诉列表
export function getReportList(params) {
  return request({
    url: '/api/complaint',
    method: 'get',
    params
  })
}
//获取报修列表
export function getRepairList(params) {
  return request({
    url: '/api/repair',
    method: 'get',
    params
  })
}

export function getLampAllList(params) {
  return request({
    url: '/api/eqLamp/list',
    method: 'get',
    params
  })
}
export function getLampInfo(params) {
  return request({
    url: '/api/eqLamp/list',
    method: 'get',
    params
  })
}
export function getMpcInfo(params) {
  return request({
    url: '/api/eqLampMpc/list',
    method: 'get',
    params
  })
}
export function getMpcList(params) {
  return request({
    url: '/api/eqLampMpc/list',
    method: 'get',
    params
  })
}
export function getEnergy() {
  return request({
    url: '/api/eqLampFailureDaily/last7',
    method: 'get',
  })
}
//获取巡检列表
export function getchecklist(params) {
  return request({
    url: '/api/eqLampCheck',
    method: 'get',
    params
  })
}
//获取告警列表
export function getlampwarn(params) {
  return request({
    url: '/api/eqLampFailure',
    method: 'get',
    params
  })
}
//获取亮度方案列表
export function getlampPwm(params) {
  return request({
    url: '/api/eqLampScheme',
    method: 'get',
    params
  })
}
//获取开关方案列表
export function getlampSwitch(params) {
  return request({
    url: '/api/eqLampSchemeSwitch',
    method: 'get',
    params
  })
}
//四个开关
export function switchABCD(data) {
  return request({
    url: '/api/eqLampMpc/setSwitch',
    method: 'post',
    data
  })
}
//路灯亮度方案
export function getSchemeList(id) {
  return request({
    url: '/api/eqLampMpcSchema?mpcId='+id,
    method: 'get',
  })
}
//路灯开关方案
export function getSwtichList(id) {
  return request({
    url: '/api/eqLampSchemeSwitch?mpcId='+id,
    method: 'get',
  })
}
export default {
    getRepairList,
    getReportList,
    getNoticeList,
    getWarnList,
    getEquipmentList,
    getVillageList,
    getBigVillage,
    getYearVillage,
    getReportLine,
    getReportType,
    getRepairLine,
    getEstateTop,
    getsummary,
    getCameraPlayUrl,
    getPreview,
    getPreviewList,
    getParkList,
    getParkingLot,
    getauth,
    getApi,
    getNews,
    getEstateById,
    getWeather,
    getLampAllList,
    getLampInfo,
    getMpcInfo,
    getMpcList,
    getEnergy,
    getchecklist,
    getlampwarn,
    getlampPwm,
    getlampSwitch,
    switchABCD,
    getSchemeList,
    getSwtichList
}
