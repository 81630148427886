<template>
  <div class="padding-10-20 v">
    <div v-if="data" class="padding-10-0 lampinfo" v-show="!ismpc">
      <div class="fs-large h m c bold" style="padding-bottom: 10px">路灯</div>
      <div class="h c padding-0-10">
        <div class="fs-small">路灯名称：{{ data.lampAlias }}</div>
        <div class="fs-small" style="margin-left:10px">路灯编号：{{ data.lampKey }}</div>
        <div class="fs-small padding-0-20">路灯位置：{{ data.address }}</div>
        <div class="h c m fs-small">
          状态：
          <dot :color="status[data.status].color" same>{{ status[data.status].label }}</dot>
        </div>
      </div>
      <div class="padding-10-0">
        <el-button type="primary" @click="schemeClick" plain size="mini" style="margin-left:10px">亮度方案</el-button>
        <el-button type="primary" @click="switchClick" plain size="mini">开关方案</el-button>
      </div>

      <div class="h sb padding-0-10">
        <div class="h sb">
          <div class="h m c fs-small">电流：</div>
          <a-echarts :a="data.a"></a-echarts>
        </div>
        <div class="h sb">
          <div class="h m c fs-small">电压：</div>
          <v-echarts :v="data.v"></v-echarts>
        </div>
        <div class="h sb">
          <div class="h m c fs-small">功率：</div>
          <p-echarts :p="data.p"></p-echarts>
        </div>
      </div>
      <div class="padding-0-10" v-if="lampSchemeVisible">
        <div style="margin-bottom:10px" class="fs-small">亮度方案</div>
        <el-table :data="lampSchemeData" key="1" border height="200" style="width: 100%">
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column prop="title" label="方案名称" />
          <el-table-column prop="time1" label="第一次调光时间" />
          <el-table-column prop="time2" label="第二次调光时间" />
          <el-table-column prop="time3" label="第三次调光时间" />
          <el-table-column prop="time4" label="第四次调光时间" />
          <el-table-column prop="time5" label="第五次调光时间" />
          <el-table-column prop="time6" label="第六次调光时间" />
          <el-table-column prop="pwm1" label="第一次调光亮度" />
          <el-table-column prop="pwm2" label="第二次调光亮度" />
          <el-table-column prop="pwm3" label="第三次调光亮度" />
          <el-table-column prop="pwm4" label="第四次调光亮度" />
          <el-table-column prop="pwm5" label="第五次调光亮度" />
          <el-table-column prop="pwm6" label="第六次调光亮度" />
        </el-table>
      </div>
      <div class="padding-0-10" v-if="lampSwitchVisible">
        <div style="margin-bottom:10px" class="fs-small">开关方案</div>
        <el-table :data="lampSchemeData" key="2" border height="200" style="width: 100%">
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column prop="title" width="100px" label="方案名称" />
          <el-table-column prop="ontime1a" label="第一次打开A开关的时间" width="150px" />
          <el-table-column prop="offtime1a" label="第一次关闭A开关的时间" width="150px" />
          <el-table-column prop="ontime2a" label="第二次打开A开关的时间" width="150px" />
          <el-table-column prop="offtime2a" label="第二次关闭A开关的时间" width="150px" />
          <el-table-column prop="ontime1b" label="第一次打开B开关的时间" width="150px" />
          <el-table-column prop="offtime1b" label="第一次关闭B开关的时间" width="150px" />
          <el-table-column prop="ontime2b" label="第二次打开B开关的时间" width="150px" />
          <el-table-column prop="offtime2b" label="第二次关闭B开关的时间" width="150px" />
          <el-table-column prop="ontime1c" label="第一次打开C开关的时间" width="150px" />
          <el-table-column prop="offtime1c" label="第一次关闭C开关的时间" width="150px" />
          <el-table-column prop="ontime2c" label="第二次打开C开关的时间" width="150px" />
          <el-table-column prop="offtime2c" label="第二次关闭C开关的时间" width="150px" />
          <el-table-column prop="ontime1d" label="第一次打开D开关的时间" width="150px" />
          <el-table-column prop="offtime1d" label="第一次关闭D开关的时间" width="150px" />
          <el-table-column prop="ontime2d" label="第二次打开D开关的时间" width="150px" />
          <el-table-column prop="offtime2d" label="第二次关闭D开关的时间" width="150px" />
        </el-table>
      </div>
    </div>
    <div v-if="mpcdata" class="lampinfo flex padding-0-10" style="margin-top: 10px;padding-bottom:10px">
      <div class="fs-large h m c padding-10-0 bold" style="padding-bottom: 10px">集中器</div>
      <div class="padding-10-0">
        <el-button type="primary" @click="mpcSchemeClick" plain size="mini" style="margin-left:10px">亮度方案</el-button>
        <el-button type="primary" @click="mpcSwitchClick" plain size="mini">开关方案</el-button>
      </div>
      <div class="h sb">
        <div class="h sb">
          <div class="h m c fs-small">电流：</div>
          <a-mpc :data="[mpcdata.la || 0, mpcdata.lb || 0, mpcdata.lc || 0]"></a-mpc>
        </div>
        <div class="h sb">
          <div class="h m c fs-small">电压：</div>
          <v-mpc :data="[mpcdata.ua || 0, mpcdata.ub || 0, mpcdata.uc || 0]"></v-mpc>
        </div>
        <div class="h sb">
          <div class="h m c fs-small">功率：</div>
          <p-mpc :data="[mpcdata.pa || 0, mpcdata.pb || 0, mpcdata.pc || 0]"></p-mpc>
        </div>
      </div>
      <div v-if="mpcSchemeVisible">
        <div style="margin-bottom:10px" class="fs-small">亮度方案</div>
        <el-table :data="mpcSchemeData" key="1" border height="200" style="width: 100%">
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column prop="title" label="方案名称" />
          <el-table-column prop="time1" label="第一次调光时间" />
          <el-table-column prop="time2" label="第二次调光时间" />
          <el-table-column prop="time3" label="第三次调光时间" />
          <el-table-column prop="time4" label="第四次调光时间" />
          <el-table-column prop="time5" label="第五次调光时间" />
          <el-table-column prop="time6" label="第六次调光时间" />
          <el-table-column prop="pwm1" label="第一次调光亮度" />
          <el-table-column prop="pwm2" label="第二次调光亮度" />
          <el-table-column prop="pwm3" label="第三次调光亮度" />
          <el-table-column prop="pwm4" label="第四次调光亮度" />
          <el-table-column prop="pwm5" label="第五次调光亮度" />
          <el-table-column prop="pwm6" label="第六次调光亮度" />
        </el-table>
      </div>
      <div v-if="mpcSwitchVisible">
        <div style="margin-bottom:10px" class="fs-small">开关方案</div>
        <el-table :data="mpcSchemeData" key="2" border height="200" style="width: 100%">
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column prop="title" width="100px" label="方案名称" />
          <el-table-column prop="ontime1a" label="第一次打开A开关的时间" width="150px" />
          <el-table-column prop="offtime1a" label="第一次关闭A开关的时间" width="150px" />
          <el-table-column prop="ontime2a" label="第二次打开A开关的时间" width="150px" />
          <el-table-column prop="offtime2a" label="第二次关闭A开关的时间" width="150px" />
          <el-table-column prop="ontime1b" label="第一次打开B开关的时间" width="150px" />
          <el-table-column prop="offtime1b" label="第一次关闭B开关的时间" width="150px" />
          <el-table-column prop="ontime2b" label="第二次打开B开关的时间" width="150px" />
          <el-table-column prop="offtime2b" label="第二次关闭B开关的时间" width="150px" />
          <el-table-column prop="ontime1c" label="第一次打开C开关的时间" width="150px" />
          <el-table-column prop="offtime1c" label="第一次关闭C开关的时间" width="150px" />
          <el-table-column prop="ontime2c" label="第二次打开C开关的时间" width="150px" />
          <el-table-column prop="offtime2c" label="第二次关闭C开关的时间" width="150px" />
          <el-table-column prop="ontime1d" label="第一次打开D开关的时间" width="150px" />
          <el-table-column prop="offtime1d" label="第一次关闭D开关的时间" width="150px" />
          <el-table-column prop="ontime2d" label="第二次打开D开关的时间" width="150px" />
          <el-table-column prop="offtime2d" label="第二次关闭D开关的时间" width="150px" />
        </el-table>
      </div>
      <div class="sgroup h c r padding-10-0 fs-small">
        开关A：
        <el-switch style="padding-right: 10px" v-model="mpcdata.switchA" active-text="开" inactive-text="关" active-color="#13ce66" inactive-color="#ffffff" @change="
            changeSwitchA(
              mpcdata,
              $event,
              mpcdata.switchB,
              mpcdata.switchC,
              mpcdata.switchD
            )
          "></el-switch>开关B：
        <el-switch style="padding-right: 10px" v-model="mpcdata.switchB" active-text="开" inactive-text="关" active-color="#13ce66" inactive-color="#ffffff" @change="
            changeSwitchB(
              mpcdata,
              $event,
              mpcdata.switchA,
              mpcdata.switchC,
              mpcdata.switchD
            )
          "></el-switch>开关C：
        <el-switch style="padding-right: 10px" v-model="mpcdata.switchC" active-text="开" inactive-text="关" active-color="#13ce66" inactive-color="#ffffff" @change="
            changeSwitchC(
              mpcdata,
              $event,
              mpcdata.switchA,
              mpcdata.switchB,
              mpcdata.switchD
            )
          "></el-switch>开关D：
        <el-switch style="padding-right: 10px" v-model="mpcdata.switchD" active-text="开" inactive-text="关" active-color="#13ce66" inactive-color="#ffffff" @change="
            changeSwitchD(
              mpcdata,
              $event,
              mpcdata.switchA,
              mpcdata.switchB,
              mpcdata.switchC
            )
          "></el-switch>
      </div>
      <el-descriptions class="margin-top" :column="4" size="mini" border>
        <!-- <el-descriptions-item
          label-class-name="my-label"
          content-class-name="my-content"
        >
          <template slot="label"> dataKey </template>
          {{ mpcdata.dataKey }}
        </el-descriptions-item>-->
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">设备编号</template>
          {{ mpcdata.dataKey }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">名称</template>
          {{ mpcdata.name }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">地区</template>
          {{ mpcdata.areaName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">相电压a</template>
          {{ mpcdata.ua }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">相电压b</template>
          {{ mpcdata.ub }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">相电压c</template>
          {{ mpcdata.uc }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">线电压a</template>
          {{ mpcdata.uab }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">线电压b</template>
          {{ mpcdata.ubc }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">线电压c</template>
          {{ mpcdata.uca }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">电流a</template>
          {{ mpcdata.la }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">电流b</template>
          {{ mpcdata.lb }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">电流c</template>
          {{ mpcdata.lc }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">功率a</template>
          {{ mpcdata.pa }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">功率b</template>
          {{ mpcdata.pb }}
        </el-descriptions-item>

        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">功率c</template>
          {{ mpcdata.pc }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">功率因数</template>
          {{ mpcdata.cosq }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">频率</template>
          {{ mpcdata.f }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">用电量</template>
          {{ mpcdata.ep }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">地址</template>
          {{ mpcdata.address }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">同步时间</template>
          {{ mpcdata.syncAt }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">1路电流检测</template>
          {{ mpcdata.av1 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">2路电流检测</template>
          {{ mpcdata.av2 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">3路电流检测</template>
          {{ mpcdata.av3 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">4路电流检测</template>
          {{ mpcdata.av4 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">5路电流检测</template>
          {{ mpcdata.av5 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">6路电流检测</template>
          {{ mpcdata.av6 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">7路电流检测</template>
          {{ mpcdata.av7 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">8路电流检测</template>
          {{ mpcdata.av8 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">9路电流检测</template>
          {{ mpcdata.av9 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">10路电流检测</template>
          {{ mpcdata.av10 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">11路电流检测</template>
          {{ mpcdata.av11 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">12路电流检测</template>
          {{ mpcdata.av12 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">13路电流检测</template>
          {{ mpcdata.av13 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">14路电流检测</template>
          {{ mpcdata.av14 }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
          <template slot="label">15路电流检测</template>
          {{ mpcdata.av15 }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import aEcharts from "../lampEcharts/aechart.vue";
import vEcharts from "../lampEcharts/vechart.vue";
import pEcharts from "../lampEcharts/pechart.vue";
import aMpc from "../lampEcharts/ampc.vue";
import vMpc from "../lampEcharts/vmpc.vue";
import pMpc from "../lampEcharts/pmpc.vue";
import { switchABCD, getSchemeList, getSwtichList } from "@/api/data";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    mpcdata: {
      type: Object,
      default: {},
    },
    ismpc: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    aMpc,
    vMpc,
    pMpc,
    aEcharts,
    vEcharts,
    pEcharts,
  },
  data() {
    return {
      status: [
        { id: 0, name: "灭灯", label: "灭灯", color: "#a9a7aa" },
        { id: 1, name: "亮灯", label: "亮灯", color: "#00b05b" },
        { id: 2, name: "失联", label: "失联", color: "#ffba15" },
        { id: 3, name: "故障", label: "故障", color: "#ff0030" },
      ],
      lampSchemeData: [],
      lampSwitchData: [],
      lampSchemeVisible: false,
      lampSwitchVisible: false,
      mpcSchemeData: [],
      mpcSwitchData: [],
      mpcSchemeVisible: false,
      mpcSwitchVisible: false,
    };
  },
  methods: {
    schemeClick() {
      this.lampSchemeVisible = !this.lampSchemeVisible;
      this.lampSwitchVisible = false;
      getSchemeList(this.data.deviceId).then((res) => {
        this.lampSchemeData =
          res.content.map((r) => {
            return r.eqLampScheme;
          }) || [];
      });
    },
    switchClick() {
      this.lampSwitchVisible = !this.lampSwitchVisible;
      this.lampSchemeVisible = false;
      getSwtichList(this.data.deviceId).then((res) => {
        this.lampSchemeData = res.content || [];
      });
    },
    mpcSchemeClick() {
      this.mpcSchemeVisible = !this.mpcSchemeVisible;
      this.mpcSwitchVisible = false;
      getSchemeList(this.mpcdata.id).then((res) => {
        this.mpcSchemeData =
          res.content.map((r) => {
            return r.eqLampScheme;
          }) || [];
      });
    },
    mpcSwitchClick() {
      this.mpcSwitchVisible = !this.mpcSwitchVisible;
      this.mpcSchemeVisible = false;
      getSwtichList(this.mpcdata.id).then((res) => {
        this.mpcSchemeData = res.content || [];
      });
    },
    changeSwitchA(data, d, valb, valc, vald) {
      switchABCD({
        id: data.id,
        switchA: d ? 1 : 0,
        switchB: valb ? 1 : 0,
        switchC: valc ? 1 : 0,
        switchD: vald ? 1 : 0,
      })
        .then((res) => {
          this.$message.success("设置开关成功");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    changeSwitchB(data, d, vala, valc, vald) {
      switchABCD({
        id: data.id,
        switchB: d ? 1 : 0,
        switchA: vala ? 1 : 0,
        switchC: valc ? 1 : 0,
        switchD: vald ? 1 : 0,
      })
        .then((res) => {
          this.$message.success("设置开关成功");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    changeSwitchC(data, d, vala, valb, vald) {
      switchABCD({
        id: data.id,
        switchC: d ? 1 : 0,
        switchA: vala ? 1 : 0,
        switchB: valb ? 1 : 0,
        switchD: vald ? 1 : 0,
      })
        .then((res) => {
          this.$message.success("设置开关成功");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    changeSwitchD(data, d, vala, valb, valc) {
      switchABCD({
        id: data.id,
        switchD: d ? 1 : 0,
        switchA: vala ? 1 : 0,
        switchB: valb ? 1 : 0,
        switchC: valc ? 1 : 0,
      })
        .then((res) => {
          this.$message.success("设置开关成功");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="scss">
.lampinfo {
  border: #2e90f5 solid 1px;
  border-radius: 10px;
  .el-descriptions {
    .is-bordered .el-descriptions-item__cell {
      border: 0 !important;
    }
    .my-label {
      background-color: #2e90f5;
      color: white;
    }
    .my-content {
      background-color: #2e90f5;
      color: white;
    }
  }
  .sgroup {
    color: white;
  }
}
</style>