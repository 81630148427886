<template>
  <div>
    <div class="padding-20-0">
      时间：2022-02-22 12:20:36
      <br />
      设备名称：{{ data.name }}
      <br />红绿灯位置：阿瓦提县团结东路
      <br />传感器：正常
      <br />工作温度：38度
      <br />区域负责人：张伟
      <br />联系方式：13152561159
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
};
</script>