<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      chart2: null,
    };
  },
  methods: {
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      this.chart2.clear();
      this.chart2.setOption({
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{c0}%",
        },
        xAxis: {
          type: "category",
          data: [
            "02/01",
            "02/02",
            "02/03",
            "02/04",
            "02/05",
            "02/06",
            "02/07",
            "02/08",
            "02/09",
            "02/10",
            "02/11",
            "02/12",
            "02/13",
            "02/14",
            "02/15",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [
              70.67, 90.65, 80.35, 80.58, 75.54, 79.53, 95.42, 88.52, 75.3,
              96.22, 89.6, 74.66, 68.55, 73.8, 86.04
            ],
            type: "line",
            smooth: true,
            areaStyle: {},
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 12,
                  },
                  formatter: "{c0}%",
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>