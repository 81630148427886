<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      chart2: null,
      num: "12",
    };
  },
  methods: {
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      this.chart2.clear();
      this.chart2.setOption({
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2021-07", "2021-08", "2021-09", "2021-10", "2021-11", "2021-12", "2022-01"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "正常数量",
            type: "line",
            data: [24, 19, 22, 22, 23, 20, 24],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
          {
            name: "异常数量",
            type: "line",
            data: [0, 5, 2, 2, 1, 4, 0],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [
                { type: "average", name: "Avg" },
                [
                  {
                    symbol: "none",
                    x: "90%",
                    yAxis: "max",
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "start",
                      formatter: "Max",
                    },
                    type: "max",
                    name: "最高点",
                  },
                ],
              ],
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>