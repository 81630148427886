<template>
  <div class="app-dashboard-analy h sb" :class="{ modal: visible }">
    <transition enter-active-class="animated faster fadeInLeft" leave-active-class="animated faster fadeOutLeft">
      <div class="page-title" style="width: 28%;" v-if="residential && visible">
        <div class="flex _text">{{residential.name}}</div>
        <div class="_close el-icon-close no-flex" @click="doClose"></div>
      </div>
    </transition>

    <transition enter-active-class="animated faster fadeInLeft" leave-active-class="animated faster fadeOutLeft">
      <div class="panel v" style="width: 25%;" v-if="visible">
        <panel title="小区基本信息">
          <div class="h">
            <number-view :value="parseInt(residential.builderNumber)" title="楼栋数" icon="fa-solid fa-archway" style="width: 30%;" />
            <number-view :value="parseInt(residential.familyNumber)" title="总户数" icon="fa-solid fa-archway" style="width: 30%;" />
            <number-view :value="parseInt(residential.realFamilyNumber)" title="已入住户数" icon="fa-solid fa-building fc-s" class="flex" />
          </div>
        </panel>
        <panel title="小区公告滚动" style="height: 33%;">
          <seamless-scroll :data="announcementData" :class-option="classOption">
            <div class="roll-item" v-for="(item, index) in announcementData" :key="index">
              <div>
                <span class="_title">标题&emsp;</span>
                {{ item.title }}
              </div>
              <div>
                <span class="_title">时间&emsp;</span>
                <span v-html="item.createTime"></span>
              </div>
            </div>
          </seamless-scroll>
        </panel>

        <panel title="常住人口和流动人口统计表" class="flex">
          <!-- <repair-trend /> -->
          <migrant-population />
        </panel>
      </div>
    </transition>

    <transition enter-active-class="animated faster fadeInRight" leave-active-class="animated faster fadeOutRight">
      <div class="panel v" style="width: 25%;" v-if="visible">
        <panel title="小区基础设施统计分析" class="flex">
          <plot-facility />
        </panel>
        <!-- <panel title="物业公司信息" class="flex">
          <div v-if="residential.estateId">
            <div class="roll-item">
              <div>
                <span class="_title">法人&emsp;{{estateData.legalPersonName}}</span>
              </div>
              <div>
                <span class="_title">责任人&emsp;{{estateData.principalName}}</span>
              </div>
              <div>
                <span class="_title">物业公司名称&emsp;{{estateData.name}}</span>
              </div>
              <div>
                <span class="_title">企业注册地址&emsp;{{estateData.registerAddress}}</span>
              </div>
            </div>
          </div>
          <div v-else style="text-align: center;" class="padding-20">该小区暂无物业公司</div>
        </panel>-->

        <panel title="小区监控视频" class="no-flex">
          <div class="padding-10" v-loading="camera.loading" :style="{minHeight: cameraBoxMinHeight}">
            <template v-if="camera.store && camera.store.length">
              <el-radio-group v-model="camera.current" style="margin-bottom: 5px;">
                <el-radio-button v-for="c in camera.store" :key="c.cameraIndexCode" :label="c.cameraIndexCode">{{c.cameraName}}</el-radio-button>
              </el-radio-group>
              <camera-video :code="camera.current" />
            </template>
            <div v-else-if="!camera.loading" style="color: white; text-align: center;" :style="{lineHeight: cameraBoxMinHeight}">当前小区暂无监控视频</div>
          </div>
          <!-- <div class="padding-10">
            <video v-for="i in 4" :key="i" src="/video/street_c01.mp4" autoplay loop style="width:50%; height:50%; border: transparent solid; border-width: 5px; box-sizing: border-box;" />
          </div>-->
        </panel>

        <!-- <panel title="小区信息">
          <div class="h">
            <number-view :value="totleMeasure" title="总面积" icon="fa-solid fa-border-top-left" style="width: 50%;" />
            <number-view :value="totleFamilyNumber" title="总户数" icon="fa-solid fa-users" class="flex" />
          </div>
        </panel>-->
        <!-- <panel title="超大小区" style="height: 33%;">
          <super-community />
        </panel>
        <panel title="小区年份" class="flex">
          <community-age />
        </panel>-->
      </div>
    </transition>
  </div>
</template>

<script>
import MigrantPopulation from "./charts/migrant-population";
import PlotFacility from "./charts/plot-facility";
import CommunityAge from "./charts/community-age";
import SuperCommunity from "./charts/super-community";
import ComplaintTrend from "./charts/complaint-trend";
import RepairTrend from "./charts/repair-trend";
import { getNews, getEstateById, getPreviewList } from "@/api/data";
export default {
  data() {
    return {
      announcementData: [],
      estateData: [],
      classOption: {
        step: 0.65,
        limitMoveNum: 1,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 3000,
      },
      camera: {
        loading: false,
        store: [],
        current: null,
      },
    };
  },
  components: {
    PlotFacility,
    CommunityAge,
    SuperCommunity,
    ComplaintTrend,
    RepairTrend,
    MigrantPopulation,
  },
  props: {
    visible: Boolean,
    residential: Object,
  },
  watch: {
    residential: {
      handler: function () {
        this.getPlotNews();
        this.loadEstateData();
        this.loadCameras();
      },
      immediate: true,
    },
  },
  computed: {
    cameraBoxMinHeight() {
      let h = Math.round(((window.innerWidth * 0.25 - 50) / 16) * 9 + 37);
      return h + "px";
    },
  },

  methods: {
    doClose() {
      this.$emit("close");
    },
    loadEstateData() {
      if (this.residential && this.residential.estateId) {
        getEstateById(this.residential.estateId).then((res) => {
          this.estateData = res;
        });
      }
    },

    loadCameras() {
      if (this.residential) {
        this.camera.loading = true;
        this.camera.current = null;
        this.camera.store = [];
        getPreviewList({ type: 0, entityId: this.residential.originInfo.id })
          .then((res) => {
            if (res && res.length) {
              res.sort((a, b) => {
                return a.cameraName > b.cameraName ? 1 : -1;
              });
              this.camera.store = res;
              this.camera.current = res[0].cameraIndexCode;
            }
          })
          .finally(() => {
            this.camera.loading = false;
          });
      }
    },

    getPlotNews() {
      getNews().then((res) => {
        if (res && res.content) {
          this.announcementData = res.content;
        }
      });
    },
  },
};
</script>