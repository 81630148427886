<template>
  <transition
    enter-active-class="animated faster fadeInDown"
    leave-active-class="animated faster fadeOutUp"
  >
    <div class="panel no-flex h" v-if="visible" style="margin-bottom: 10px">
      <!-- <cover style="width: 25%; margin: 0 -10px 0 0;" />
      <lamp style="width: 25%; margin: 0 -10px 0 0;" />
      <red style="width: 25%; margin: 0 -10px 0 0;" />
      <monitor class="flex" style=" margin: 0 -10px 0 0;" /> -->
      <div class="flex">
        <panel title="亮度方案记录">
          <seamless-scroll :data="pwmList" :class-option="classOption">
            <div
              class="roll-item"
              v-for="(item, index) in pwmList"
              :key="index"
            >
              <div>
                第一次调光时间：{{ item.time1 }}，第一次调光亮度：{{
                  item.pwm1
                }}
              </div>
              <div>
                第二次调光时间：{{ item.time2 }}，第二次调光亮度：{{
                  item.pwm2
                }}
              </div>
              <div>
                第三次调光时间：{{ item.time3 }}，第三次调光亮度：{{
                  item.pwm3
                }}
              </div>
              <div>
                第四次调光时间：{{ item.time4 }}，第四次调光亮度：{{
                  item.pwm4
                }}
              </div>
              <div>
                第五次调光时间：{{ item.time5 }}，第五次调光亮度：{{
                  item.pwm5
                }}
              </div>
              <div>
                第六次调光时间：{{ item.time6 }}，第六次调光亮度：{{
                  item.pwm6
                }}
              </div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
      <div class="flex">
        <panel title="开关方案记录">
          <seamless-scroll :data="switchLIst" :class-option="classOption">
            <div
              class="roll-item"
              v-for="(item, index) in switchLIst"
              :key="index"
            >
              <div>
                第一次打开A开关时间：{{
                  item.ontime1a
                }}，第一次关闭A开关时间：{{ item.offtime1a }}
              </div>
              <div>
                第二次打开A开关时间：{{
                  item.ontime2a
                }}，第二次关闭A开关时间：{{ item.offtime2a }}
              </div>

              <div>
                第一次打开B开关时间：{{
                  item.ontime1b
                }}，第一次关闭B开关时间：{{ item.offtime1b }}
              </div>
              <div>
                第二次打开B开关时间：{{
                  item.ontime2b
                }}，第二次关闭B开关时间：{{ item.offtime2b }}
              </div>

              <div>
                第一次打开C开关时间：{{
                  item.ontime1c
                }}，第一次关闭C开关时间：{{ item.offtime1c }}
              </div>
              <div>
                第二次打开C开关时间：{{
                  item.ontime2c
                }}，第二次关闭C开关时间：{{ item.offtime2c }}
              </div>

              <div>
                第一次打开D开关时间：{{
                  item.ontime1d
                }}，第一次关闭D开关时间：{{ item.offtime1d }}
              </div>
              <div>
                第二次打开D开关时间：{{
                  item.ontime2d
                }}，第二次关闭D开关时间：{{ item.offtime2d }}
              </div>
              
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </div>
  </transition>
</template>

<script>
import Cover from "./cover.vue";
import Lamp from "./lamp.vue";
import Monitor from "./monitor.vue";
import Red from "./red.vue";
import { getlampPwm, getlampSwitch } from "@/api/data";
export default {
  data() {
    return {
      pwmList: [],
      switchLIst: [],
      classOption: {
        step: 0.65,
        limitMoveNum: 1,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 3000,
      },
      timer: null,
    };
  },
  props: {
    visible: Boolean,
  },
  components: {
    Cover,
    Monitor,
    Red,
    Lamp,
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.timer = setInterval(this.doWarnListener, 20000);
          this.doWarnListener();
        } else {
          this.stopWarnListener();
        }
      },
    },
  },
  methods: {
    doWarnListener() {
      Promise.all([
        getlampPwm({
          page: 0,
          size: 10,
        }),
        getlampSwitch({ page: 0, size: 10 }),
      ]).then((res) => {
        this.pwmList = res[0].content || [];
        this.switchLIst = res[1].content || [];
      });
    },
    stopWarnListener() {
      if (this.timer) clearInterval(this.timer);
    },
  },
};
</script>