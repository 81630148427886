<template>
  <div class="v">
    <div class="no-flex h fc-w">
      <div class="padding-10 ta-c" style="width: 33.3%;">
        <div>计划任务</div>
        <count-to :end-val="counts.plan" class="f-bud fs-huge" />
      </div>
      <div class="padding-10 ta-c" style="width: 33.3%;">
        <div>廉租房</div>
        <count-to :end-val="counts.low" class="f-bud fs-huge" />
      </div>
      <div class="padding-10 ta-c" style="width: 33.3%;">
        <div>公租房</div>
        <count-to :end-val="counts.public" class="f-bud fs-huge" />
      </div>
    </div>
    <div class="flex" ref="chart"></div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
  components: { CountTo },
  data() {
    return {
      counts: {
        plan: 0,
        public: 0,
        low: 0,
      },
      timer: null,
    };
  },
  methods: {
    init() {
      let data = [
          { year: 2008, plan: 104, public: 0, low: 108 },
          { year: 2009, plan: 1026, public: 0, low: 929 },
          { year: 2010, plan: 648, public: 0, low: 1043 },
          { year: 2011, plan: 1424, public: 456, low: 936 },
          { year: 2012, plan: 1675, public: 584, low: 1152 },
          { year: 2013, plan: 1333, public: 865, low: 469 },
          { year: 2014, plan: 942, public: 944, low: 0 },
          { year: 2015, plan: 2265, public: 1931, low: 0 },
          { year: 2016, plan: 231, public: 231, low: 0 },
          { year: 2017, plan: 900, public: 900, low: 0 },
          { year: 2018, plan: 0, public: 0, low: 0 },
          { year: 2019, plan: 1517, public: 1517, low: 0 },
          { year: 2020, plan: 1860, public: 1860, low: 0 },
          { year: 2021, plan: 224, public: 224, low: 0 },
          { year: 2022, plan: 420, public: 0, low: 0 },
        ],
        c = {
          plan: 0,
          public: 0,
          low: 0,
        };

      data.forEach((d) => {
        c.plan += d.plan;
        c.public += d.public;
        c.low += d.low;
      });
      this.counts = c;

      let chart = echarts.init(this.$refs.chart, "owner");
      var option = {
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        dataZoom: [
          {
            xAxisIndex: 0,
            show: false,
            type: "slider",
            startValue: 0,
            endValue: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: data.map((d) => d.year),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.map((d) => d.plan),
            type: "bar",
            barWidth: 3,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffffffcc" },
                  { offset: 1, color: "#ffffff00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffffff" },
                  { offset: 1, color: "#ffffff33" },
                ]),
              },
            },
            name: "计划任务",
          },
          {
            data: data.map((d) => d.public),
            type: "bar",
            barWidth: "75%",
            label: {
              show: true,
              position: "inside",
              fontSize: 11,
              coloe: "#ffffff",
              formatter: function(p) {
                if (p.data > 0) return p.data;
                return "";
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },
            stack: "finish",
            name: "公租房",
          },
          {
            data: data.map((d) => d.low),
            type: "bar",
            label: {
              show: true,
              position: "inside",
              fontSize: 11,
              coloe: "#ffffff",
              formatter: function(p) {
                if (p.data > 0) return p.data;
                return "";
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908cc" },
                  { offset: 1, color: "#F5390800" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#F5390833" },
                ]),
              },
            },
            stack: "finish",
            name: "廉租房",
          },
        ],
      };

      chart.setOption(option);

      this.timer = setInterval(function () {
        if (option.dataZoom[0].endValue == data.length) {
          option.dataZoom[0].startValue = 0;
          option.dataZoom[0].endValue = 10;
        } else {
          option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
          option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
        }
        chart.setOption(option);
      }, 1000);
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  mounted() {
    this.init();
  },
};
</script>