<template>
  <div class="app-dashboard-analy h" :class="{ modal: visible }">
    <div class="v flex">
      <transition
        enter-active-class="animated faster fadeInLeft"
        leave-active-class="animated faster fadeOutLeft"
      >
        <div class="panel v flex" style="width: 33.3%" v-if="visible">
          <panel title="路灯数量">
            <div class="h">
              <number-view
                :value="counts.lamp.count"
                title="总数"
                icon="fa-solid fa-lightbulb"
                style="width: 30%"
              />
              <number-view
                :value="counts.lamp.successCount"
                title="正常"
                icon="fa-solid fa-circle-check fc-s"
                style="width: 30%"
              />
              <number-view
                :value="counts.lamp.errorCount"
                title="异常"
                icon="fa-solid fa-triangle-exclamation fc-e"
                class="flex"
              />
            </div>
          </panel>

          <panel title="路灯巡检记录" class="flex">
            <seamless-scroll :data="infoList" :class-option="classOption">
              <div
                class="roll-item"
                v-for="(item, index) in infoList"
                :key="index"
              >
                <div>
                  <span class="_title">地址&emsp;</span>
                  {{ item.eqLampMpc.address }}
                </div>
                <div>
                  <span class="_title">名称&emsp;</span>
                  {{ item.lampKey }}
                </div>
                <div>
                  <span class="_title">时间&emsp;</span>
                  {{ new Date(item.checkAt).format("yyyy-MM-dd") }}
                </div>
                <div>
                  <span class="_title">维护&emsp;</span>
                  {{ item.createBy }}
                </div>
              </div>
            </seamless-scroll>
          </panel>
        </div>
      </transition>

      <repair :visible="visible" style="height: 33%; margin-top: -10px" />
    </div>

    <transition
      enter-active-class="animated faster fadeInRight"
      leave-active-class="animated faster fadeOutRight"
    >
      <div class="panel v" style="width: 25%" v-if="visible">
        <panel title="路灯故障分析" style="height: 40%">
          <lamp-community />
        </panel>

        <panel title="路灯告警记录" class="flex">
          <seamless-scroll :data="infoList1" :class-option="classOption">
            <div
              class="roll-item"
              v-for="(item, index) in infoList1"
              :key="index"
            >
              <div>
                <span class="_title">名称&emsp;</span>
                {{ item.lampKey }}
              </div>
              <div>
                <span class="_title">故障时间&emsp;</span>
                {{ new Date(item.reportAt).format("yyyy-MM-dd") }}
              </div>
              <div>
                <span class="_title">修复时间&emsp;</span>
                {{ new Date(item.fixedAt).format("yyyy-MM-dd") }}
              </div>
              <div class="h">
                <span class="_title">状态&emsp;</span>
                <dot v-if="item.status != null" :color="status[item.status-1].color" same>
                  {{ status[item.status-1].label }}
                </dot>
              </div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </transition>
  </div>
</template>

<script>
import LampCommunity from "./charts/lamp-community";
import Repair from "./repair/index";
import { getchecklist, getlampwarn } from "@/api/data";
export default {
  data() {
    return {
      // 路灯维修记录
      infoList: [],
      // 路灯预警记录
      infoList1: [],
      classOption: {
        step: 0.85, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000,
      },
      status: [
        { id: 0, name: "已修复", label: "已修复", color: "#00b05b" },
        { id: 1, name: "失联", label: "失联", color: "#a9a7aa" },
        { id: 2, name: "故障", label: "故障", color: "#ff0030" },
        { id: 3, name: "其他", label: "其他", color: "#ffba15" },

      ],
    };
  },
  components: {
    LampCommunity,
    Repair,
  },
  props: {
    visible: Boolean,
    counts: Object,
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getCheckList();
          this.getlampWarn();
        }
      },
    },
  },
  methods: {
    getCheckList() {
      getchecklist({ sort: "createTime,desc", page: 0, size: 10 }).then(
        (res) => {
          if (res && res.content.length > 0) {
            this.infoList = res.content || [];
          }
        }
      );
    },
    getlampWarn() {
      getlampwarn({ sort: "createTime,desc", page: 0, size: 10 }).then(
        (res) => {
          if (res && res.content.length > 0) {
            this.infoList1 = res.content || [];
          }
        }
      );
    },
  },
};
</script>