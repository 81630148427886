<template>
  <div></div>
</template>

<script>
import { getRepairLine } from "@/api/data";
export default {
  data() {
    return {
      xData: [],
      done: [],
      submit: [],
    };
  },
  methods: {
    getRepair() {
      this.xData=[]
      this.done=[]
      this.submit=[]
      getRepairLine().then((res) => {
        if (res.length > 0) {
          var temp = JSON.parse(JSON.stringify(res)).splice(0, 6);
          temp.map((r) => {
            this.xData.push(r.title);
            this.done.push(r.doneNumber || 0)
            this.submit.push(r.submitNumber || 0)
          });
          this.init()
        }
      });
    },
    init() {
      let chart = echarts.init(this.$el, "owner");

      var option = {
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#78f0fd66",
            },
          },
        },
        xAxis: {
          data: this.xData,
        },
        yAxis: {
          splitNumber: 3,
        },
        series: [
          {
            name: "已完成",
            type: "line",
            data: this.done,
          },
          {
            name: "已提交",
            type: "line",
            data: this.submit,
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.getRepair();
    this.init();
  },
};
</script>