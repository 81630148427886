<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    init() {
      let chart = echarts.init(this.$el, "owner"),
        colors = [
          "#78f0fd",
          "#e5764a",
          "#5B8FF9",
          "#5AD8A6",
          "#5D7092",
          "#F6BD16",
          "#E86452",
          "#6DC8EC",
          "#945FB9",
          "#FF9845",
          "#1E9493",
          "#FF99C3",
        ];

      chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}个 ({d}%)",
        },
        series: [
          {
            name: "房屋性质",
            type: "pie",
            selectedMode: "single",
            radius: [0, "45%"],
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}改造\n{c}个",
              color: "#ffffff",
              textBorderWidth: 2,
              textBorderColor: "rgba(0,0,0,.6)",
            },
            itemStyle: {
              color: (p) => {
                let color = colors[p.dataIndex];
                return {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: color },
                    { offset: 1, color: color + "33" },
                  ],
                };
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 31, name: "房改房" },
              { value: 10, name: "商品房" },
              { value: 1, name: "经济适用" },
            ],
          },
          {
            name: "计划改造",
            type: "pie",
            selectedMode: "single",
            radius: ["55%", "90%"],
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}改造\n{c}个",
              color: "#ffffff",
              textBorderWidth: 2,
              textBorderColor: "rgba(0,0,0,.6)",
            },
            itemStyle: {
              color: (p) => {
                let color = colors[p.dataIndex];
                return {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: color },
                    { offset: 1, color: color + "33" },
                  ],
                };
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1, name: "2019年" },
              { value: 22, name: "2020年" },
              { value: 7, name: "2021年" },
              { value: 5, name: "2022年" },
              { value: 3, name: "2023年" },
              { value: 3, name: "2024年" },
            ],
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>