<template>
  <div class="app-dashboard-analy h sb" :class="{ modal: visible }">
    <transition
      enter-active-class="animated faster fadeInLeft"
      leave-active-class="animated faster fadeOutLeft"
    >
      <div class="panel v" style="width: 25%" v-if="visible">
        <panel title="执法仪" class="no-flex">
          <div class="h">
            <number-view
              :value="total"
              title="执法仪数量"
              icon="el-icon-video-camera-solid"
              class="flex"
            />
            <number-view
              :value="online_total"
              title="在线数"
              icon="el-icon-video-camera"
              class="flex"
            />
            <number-view
              :value="offline_total"
              title="离线数"
              icon="el-icon-video-camera-solid"
              class="flex"
            />
          </div>
        </panel>
        <panel title="执法仪在线数量分析" class="flex">
          <zf-community></zf-community>
        </panel>
      </div>
    </transition>
    <transition
      enter-active-class="animated faster fadeInRight"
      leave-active-class="animated faster fadeOutRight"
    >
      <div class="panel v" style="width: 25%" v-if="visible">
        <panel class="flex" title="执法仪预警记录">
          <seamless-scroll :data="devices" :class-option="classOption">
            <div class="roll-item" v-for="item in devices" :key="item.id">
              <div class="h">
                <div class="_title">警员名称</div>
                {{ item.abbr }}
              </div>
              <div class="h">
                <div class="_title">警员号</div>
                {{ item.vid }}
              </div>
              <div class="h">
                <div class="_title">状态</div>
                {{ item.ol == "1" ? "在线" : "离线" }}
              </div>
              <div class="h">
                <div class="_title">地址</div>
                {{ item.gs || "无" }}
              </div>
              <div class="h">
                <div class="_title">时间</div>
                {{ item.gt ? item.gt.substr(0, 19) : "无" }}
              </div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </transition>
  </div>
</template>

<script>
import ZfCommunity from "./charts/zf-community";

import { getAuth, getList } from "@/api/enforce";

export default {
  data() {
    return {
      devices: [],
      classOption: {
        step: 0.65,
        limitMoveNum: 1,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 3000,
      },
      token: null,
      tokenTimer: null,
      timer: null,
      total: 0,
      online_total: 0,
      offline_total: 0,
    };
  },
  components: {
    ZfCommunity,
  },
  props: {
    visible: Boolean,
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.doTokenListener(true);
        } else {
          this.stopTokenListener();
          this.stopDeviceListener();
        }
      },
    },
  },
  methods: {
    startDeviceListener() {
      this.stopDeviceListener();
      this.timer = setInterval(this.doDeviceListener, 10000);
      this.doDeviceListener();
    },
    stopDeviceListener() {
      if (this.timer) clearInterval(this.timer);
    },
    doDeviceListener() {
      this.total = 0;
      this.online_total = 0;
      this.offline_total = 0;
      getList({
        geoaddress: 1,
        toMap: 2,
        language: "zh",
        jsession: this.token,
      }).then((res) => {
        if (res && res.status) {
          (res.status || []).forEach((d) => {
            if (d.mlng == "0") d.mlng = null;
            if (d.mlat == "0") d.mlat = null;
          });
          this.devices = res.status;
          this.total = this.devices.length;
          this.devices.map((r) => {
            if (r.ol == 1) {
              this.online_total++;
            }
          });
          this.offline_total = this.total - this.online_total;
          this.$emit(
            "point-change",
            this.devices
              .filter((d) => d.mlng && d.mlat && d.ol == 1)
              .map((d) => {
                return {
                  ownerId: this.$uuid(),
                  id: d.id,
                  name: d.abbr,
                  overlayIcon: "jk",
                  type: "zfdevice",
                  lng: d.mlng,
                  lat: d.mlat,
                  gt: d.gt,
                  ol: d.ol,
                  address: d.ps,
                  showTitle: true,
                };
              })
          );
        }
      });
    },

    stopTokenListener() {
      if (this.tokenTimer) clearTimeout(this.tokenTimer);
    },
    doTokenListener(init = false) {
      this.stopTokenListener();
      getAuth()
        .then((res) => {
          if (res && res.jsession) {
            this.token = res.jsession;
            if (init) {
              this.startDeviceListener();
            }
          }
        })
        .finally(() => {
          this.tokenTimer = setTimeout(this.doTokenListener, 60000);
        });
    },
  },
};
</script>
<style scoped>
._title{
  width: 80px;
  text-align-last: justify;
  text-align: justify;
  margin-right: 15px;
}
</style>