<template>
  <div>
    <div class="padding-20-0">
      <el-descriptions class="margin-top" :column="3" size="small" border>
        <el-descriptions-item>
          <template slot="label"> dataKey </template>
          {{ mpcInfo.dataKey }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 相电压ABC </template>
          {{ mpcInfo.ua }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> ub </template>
          {{ mpcInfo.ub }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> uc </template>
          {{ mpcInfo.uc }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 线电压ABC </template>
          {{ mpcInfo.uab }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> ubc </template>
          {{ mpcInfo.ubc }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> uca </template>
          {{ mpcInfo.uca }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> dataKey </template>
          {{ mpcInfo.dataKey }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 电流ABC </template>
          {{ mpcInfo.la }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> lb </template>
          {{ mpcInfo.lb }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> lc </template>
          {{ mpcInfo.lc }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 功率ABC </template>
          {{ mpcInfo.pa }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> pb </template>
          {{ mpcInfo.pb }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> pc </template>
          {{ mpcInfo.pc }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 总功率 </template>
          {{ mpcInfo.ptotal }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> qa </template>
          {{ mpcInfo.qa }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> qb </template>
          {{ mpcInfo.qb }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> qtotal </template>
          {{ mpcInfo.qtotal }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> s </template>
          {{ mpcInfo.s }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 功率因数 </template>
          {{ mpcInfo.cosq }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 频率 </template>
          {{ mpcInfo.f }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 用电量 </template>
          {{ mpcInfo.ep }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> gps经度 </template>
          {{ mpcInfo.lan }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> gps纬度 </template>
          {{ mpcInfo.lat }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> sb </template>
          {{ mpcInfo.sb }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> sc </template>
          {{ mpcInfo.sc }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> sd </template>
          {{ mpcInfo.sd }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mpcInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>