import request from '@/utils/request'

export function getList(params) {
  return request({
    url: 'http://114.215.25.249/StandardApiAction_getDeviceStatus.action',
    method: 'get',
    params,
    _uToken: false,
  })
}

//获取jession信息
export function getAuth() {
  return request({
    url: '/api/open/auth',
    method: 'get',
  })
}


export default{
  getList,
  getAuth,
}