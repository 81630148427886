<template>
  <div class="app-dashboard-number-view">
    <i :class="icon" v-if="icon"></i>
    <div class="_inner">
      <div class="_title" v-if="title">{{title}}</div>
      <count-to class="f-bud _num" :end-val="value" :suffix="suffix" :prefix="prefix" :decimals="decimals" />
    </div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
  components: { CountTo },
  props: {
    value: { type: Number, default: 0 },
    title: String,
    icon: String,
    prefix: String,
    suffix: String,
    decimals: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style lang="scss">
.app-dashboard-number-view {
  margin: 10px;
  display: flex;
  flex-direction: column;

  > ._inner {
    border-radius: 10px;
    background: url(/images/count-back.png) repeat;
    box-shadow: inset 0 0 20px 10px #000032;
    padding: 10px;
    text-align: center;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 20px;
      border-radius: 10px 0 0 10px;
      border: #78f0fd33 solid;
      border-width: 1px 0 1px 2px;
      content: "";
    }
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      border-radius: 0 10px 10px 0;
      border: #78f0fd33 solid;
      border-width: 1px 2px 1px 0;
      content: "";
    }
    > ._title {
      font-size: 14px;
      margin-bottom: 3px;
    }
    > ._num {
      line-height: 1;
      font-size: 32px;
    }
  }

  > i {
    font-size: 36px;
    color: #2e90f5;
    margin-bottom: -18px;
    z-index: 2;
    align-self: center;

    & + ._inner {
      padding-top: 23px;
    }
  }
}
</style>