<template>
  <div class="app-dashboard">
    <div id="main_dashboard_map_container"></div>

    <template v-if="masker.visible">
      <div
        class="panel-masker"
        :style="{ backgroundImage: masker.backgroundImage }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          version="1.1"
          width="100%"
          height="100%"
          style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
          "
          viewBox="0 0 1920 1080"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
        >
          <defs>
            <clipPath id="_masker_border_top">
              <path :d="masker.top" />
            </clipPath>
            <clipPath id="_masker_border_bottom">
              <path :d="masker.bottom" />
            </clipPath>
            <clipPath id="_masker_menu">
              <path :d="masker.menu" />
            </clipPath>
            <clipPath
              clipPathUnits="objectBoundingBox"
              id="_detail_alarm_round"
            >
              <path
                d="M0.505 0c0.181,0.002 0.34,0.1 0.426,0.246l-0.044 0.025c-0.077,-0.131 -0.219,-0.219 -0.382,-0.221l0 -0.05zm0.431 0.254c0.041,0.073 0.064,0.157 0.064,0.246 0,0.09 -0.023,0.174 -0.064,0.246l-0.043 -0.025c0.036,-0.065 0.057,-0.14 0.057,-0.221 0,-0.08 -0.021,-0.155 -0.057,-0.22l0.043 -0.026zm-0.005 0.501c-0.086,0.145 -0.245,0.244 -0.426,0.245l0 -0.05c0.163,-0.001 0.305,-0.09 0.382,-0.22l0.044 0.025zm-0.436 0.245c-0.181,-0.001 -0.34,-0.1 -0.426,-0.245l0.044 -0.025c0.077,0.13 0.219,0.219 0.382,0.22l0 0.05zm-0.431 -0.254c-0.041,-0.072 -0.064,-0.156 -0.064,-0.246 0,-0.089 0.023,-0.173 0.064,-0.246l0.043 0.026c-0.036,0.065 -0.057,0.14 -0.057,0.22 0,0.081 0.021,0.156 0.057,0.221l-0.043 0.025zm0.005 -0.5c0.086,-0.146 0.245,-0.244 0.426,-0.246l0 0.05c-0.163,0.002 -0.305,0.09 -0.382,0.221l-0.044 -0.025z"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="panel-masker-border-top"></div>
      <div class="panel-masker-border-bottom"></div>

      <div class="v c menus" :style="{ height: `${masker.menuHeight}px` }">
        <div class="main-menus flex h">
          <template v-for="m in menus">
            <div
              class="menu-item"
              :key="m.id"
              :class="{
                checked: currentMenu && currentMenu.indexOf(m.id) === 0,
              }"
              @click="handleMenuChange(m)"
            >
              <i :class="m.icon"></i>
              <div>{{ m.label }}</div>
            </div>
          </template>
        </div>

        <transition
          enter-active-class="animated faster fadeInDown"
          leave-active-class="animated faster fadeOutUp"
        >
          <div
            class="sub-menus h m"
            v-if="mainMenu && mainMenu.subs && mainMenu.subs.length"
          >
            <template v-for="m in mainMenu.subs">
              <div
                class="menu-item"
                :key="m.id"
                :class="{
                  checked: currentMenu && currentMenu.indexOf(m.id) === 0,
                }"
                @click="handleMenuChange(m)"
              >
                <i :class="m.icon"></i>
                <span>{{ m.label }}</span>
              </div>
            </template>
          </div>
        </transition>
      </div>
    </template>

    <div class="header h sb">
      <!-- <button @click="mode = 'day'">DAY</button>
      <button @click="mode = 'night'">NIGHT</button>-->
      <div class="left"></div>
      <div class="right padding-10 h c s" style="color: #78eefc">
        <div class="h c environment-item">
          <i class="fa-solid fa-temperature-half"></i>
          <div>
            <div class="name">温度</div>
            <div class="value f-bud">{{ weather.temperature }}°</div>
          </div>
        </div>
        <div class="h c environment-item">
          <i class="fa-solid fa-droplet"></i>
          <div>
            <div class="name">湿度</div>
            <div class="value f-bud">{{ weather.humidity }}%</div>
          </div>
        </div>
        <div class="h c environment-item">
          <i class="fa-solid fa-leaf"></i>
          <div>
            <div class="name">空气质量</div>
            <div class="value f-bud">{{ weather.quality }}</div>
          </div>
        </div>
        <div class="ta-c f-bud lh-100" style="width: 110px">
          <div style="font-size: 20px">{{ now.format("yyyy-MM-dd") }}</div>
          <div style="font-size: 28px">{{ now.format("hh:mm:ss") }}</div>
        </div>
      </div>
    </div>
    <div class="box h">
      <div class="no-flex v">
        <div class="top-left no-flex"></div>
        <div class="center-left flex"></div>
        <div class="bottom-left no-flex"></div>
      </div>
      <div class="gap flex v sb"></div>
      <div class="no-flex v">
        <div class="h">
          <div class="top-center-left no-flex"></div>
          <div class="top-center-gap flex"></div>
          <div class="top-center-right no-flex"></div>
        </div>
        <div class="logo sa-c"></div>
        <div
          class="sub-menus h sa-c"
          v-if="subMenu && subMenu.subs && subMenu.subs.length"
        >
          <div
            v-for="m in subMenu.subs"
            :key="m.id"
            class="menu-item"
            :class="{ checked: currentMenu && currentMenu.indexOf(m.id) === 0 }"
            @click="handleMenuChange(m)"
          >
            {{ m.label }}
          </div>
        </div>
      </div>
      <div class="gap flex v sb"></div>
      <div class="no-flex v">
        <div class="top-right no-flex"></div>
        <div class="center-right flex"></div>
        <div class="bottom-right no-flex"></div>
      </div>
    </div>

    <!-- <analysis-overview :visible="overview.current && overview.current.id === 'home'" />     -->
    <analysis-overview
      :visible="currentType === 'home'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight - 50}px` }"
    />
    <cover-overview
      :visible="currentType === 'cover'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <red-overview
      :visible="currentType === 'trafficlight'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <lamp-overview
      :visible="currentType === 'lamp' && lampBtnVisible"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <park-overview
      :visible="
        currentType === 'parking' &&
        detail.current &&
        detail.current.type === 'parking'
      "
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
      :park="detail.current"
      @close="handlePageClose"
    />
    <zf-view
      :visible="currentType === 'zfdevice'"
      @point-change="generateOverlays"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />

    <plot-overview
      :visible="
        currentType === 'residential' &&
        detail.current &&
        detail.current.type === 'residential'
      "
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
      :residential="detail.current"
      @close="handlePageClose"
    />

    <camera
      :visible="currentType === 'camera'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <city-camera
      :visible="currentType === 'citycamera'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <sewage-camera
      :visible="currentType === 'sewagecamera'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <heat-camera
      :visible="currentType === 'heatcamera'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />
    <gas-camera
      :visible="currentType === 'gascamera'"
      :counts="equipmentCounts"
      :style="{ paddingBottom: `${masker.menuHeight}px` }"
    />

    <!-- <div class="operator h c m">
      <div class="operat-btn">
        <div class="back"></div>
        <div class="front v c m" :class="mode" @click="switchMode">
          <i
            class="fa-solid"
            :class="mode === 'day' ? 'fa-sun' : 'fa-moon'"
          ></i>
          <span>{{ mode === "day" ? "白天" : "夜间" }}</span>
        </div>
      </div>
    </div>-->

    <transition
      enter-active-class="animated faster zoomIn"
      leave-active-class="animated faster zoomOut"
    >
      <div class="detail-line" v-if="detail.visible">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          :width="`${detail.line.width}px`"
          :height="`${detail.line.height}px`"
          version="1.1"
          style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
          "
          :viewBox="`0 0 ${detail.line.width} ${detail.line.height}`"
        >
          <polyline
            :points="detail.line.points"
            style="fill: #2e90f5; stroke-width: 0"
          />
        </svg>
      </div>
    </transition>

    <e-details
      :visible.sync="detail.visible"
      :data="detail.current"
      @close="doResetMarkerIcon"
    ></e-details>

    <e-lamp
      :visible="lampvisible"
      :data="lampdata"
      @visibleHandle="lampvisible = false"
    />

    <div class="erath" v-if="erath"></div>
    <div
      class="lamp_show"
      v-if="this.currentType == 'lamp'"
      @click="changeLampVisible"
    >
      {{ lampBtnVisible ? "隐藏" : "显示" }}
    </div>
    <!-- <el-dialog visible width="360px">
      

      <camera-video code="65292800581314001815" 
                :width="320"
                :height="180"
              />
    </el-dialog> -->
  </div>
</template>

<script>
import ELamp from "./assembly/elamp.vue";
import { night, day } from "@/assets/map-style";
import { getWeather } from "@/api/data";
import AnalysisOverview from "../analysis/overview";
import CoverOverview from "../analysis/cover-view";
import RedOverview from "../analysis/red-view";
import LampOverview from "../analysis/lamp-view";
import ZfView from "../analysis/zf-view";
import ParkOverview from "../analysis/park-view";
import PlotOverview from "../analysis/plot";
import Camera from "../analysis/camera";
import CityCamera from "../analysis/citycamera";
import SewageCamera from "../analysis/sewagecamera";
import HeatCamera from "../analysis/heatcamera";
import GasCamera from "../analysis/gascamera";
import EDetails from "./assembly/detail.vue";

import {
  getEquipmentList,
  getVillageList,
  getPreviewList,
  getParkList,
  getLampAllList,
  getMpcList,
} from "@/api/data";

export default {
  components: {
    AnalysisOverview,
    PlotOverview,
    EDetails,
    CoverOverview,
    Camera,
    CityCamera,
    GasCamera,
    HeatCamera,
    SewageCamera,
    LampOverview,
    ParkOverview,
    ZfView,
    RedOverview,
    ELamp,
  },
  data() {
    return {
      mode: "night",
      map: null,
      lampvisible: false,
      lampdata: null,
      anslysis: false,
      erath: false,
      now: new Date(),
      store: [],
      masker: {
        visible: false,
        timer: null,
        backgroundImage: null,
        top: "",
        bottom: "",
        menu: "",
        menuHeight: 80,
      },
      weather: {
        temperature: 19,
        humidity: 46,
        quality: 16,
      },
      menus: [
        {
          id: "01",
          type: "home",
          label: "首页",
          icon: "fa-solid fa-house",
        },
        {
          id: "02",
          label: "智慧城管",
          icon: "fa-solid fa-city",
          subs: [
            {
              id: "0201",
              label: "视频监控",
              icon: "fa-solid fa-camera",
              subs: [
                {
                  id: "020101",
                  type: "citycamera",
                  label: "市政监控",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
                {
                  id: "020102",
                  type: "sewagecamera",
                  label: "污水处理监控",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
                {
                  id: "020103",
                  type: "gascamera",
                  label: "加气站监控",
                  viewAll: true,
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
                {
                  id: "020104",
                  type: "heatcamera",
                  label: "热力公司监控",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
              ],
            },
            {
              id: "0202",
              label: "市政设施",
              icon: "fa-solid fa-campground",
              subs: [
                {
                  id: "020201",
                  type: "lamp",
                  label: "路灯",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
                {
                  id: "020202",
                  type: "cover",
                  label: "井盖",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
                {
                  id: "020203",
                  type: "trafficlight",
                  label: "红绿灯",
                  filter: function (d) {
                    return !d.villageId;
                  },
                },
              ],
            },
            {
              id: "0203",
              label: "执法设备",
              type: "zfdevice",
              icon: "fa-solid fa-gavel",
            },
          ],
        },
        {
          id: "03",
          label: "智慧小区",
          icon: "fa-solid fa-archway",
          subs: [
            {
              id: "0301",
              icon: "fa-solid fa-archway",
              type: "residential",
              label: "小区",
              defaultShow: "482960beb52545028f54691d2cfe9fc0",
            },
            {
              id: "0302",
              icon: "fa-solid fa-camera",
              type: "camera",
              label: "监控",
              filter: function (d) {
                return d.originInfo.type === 0 && !!d.originInfo.entityId;
              },
              viewAll: true,
            },
            {
              id: "0303",
              icon: "fa-solid fa-lightbulb",
              type: "lamp",
              label: "小区设施",
              filter: function (d) {
                return !!d.villageId;
              },
            },
          ],
        },
        {
          id: "04",
          type: "parking",
          label: "智慧停车",
          icon: "fa-solid fa-square-parking",
          enabledTypes: ["parking"],
          defaultShow: "e24aaab1d29e456eb9a33c1224ac2a53",
        },
        {
          id: "05",
          type: "build",
          label: "智慧工地",
          icon: "fa-solid fa-industry",
          link: "http://36.189.206.200:3001/views/gov/login",
        },
      ],
      menu: {
        timer: null,
        menuIndex: 0,
        subMenuIndex: 0,
      },
      menuRoutes: [],
      currentMenu: "01",
      currentType: "home",
      currentMenuInstance: null,
      detail: {
        excludeTypes: ["parking", "residential", "citycamera"],
        line: {
          width: window.innerWidth,
          height: window.innerHeight,
          points: "",
        },
        endX: 0,
        marker: null,
        icon: null,
        current: null,
        visible: false,
      },
      markers: [],
      equipmentCounts: {
        lamp: {
          id: "lamp",
          name: "路灯",
          count: 0,
          icon: "fa-solid fa-lightbulb",
        },
        cover: {
          id: "cover",
          name: "井盖",
          count: 2570,
          icon: "fa-solid fa-life-ring",
        },
        trafficlight: {
          id: "trafficlight",
          name: "红绿灯",
          count: 157,
          icon: "fa-solid fa-traffic-light",
        },
        camera: {
          id: "camera",
          name: "监控",
          count: 154,
          icon: "fa-solid fa-video",
        },
      },
      lampIcons: [
        "./images/types/lampOff.svg",
        "./images/types/lampSuccess.svg",
        "./images/types/lampLost.svg",
        "./images/types/lampError.svg",
      ],
      lampBtnVisible: true,
    };
  },
  computed: {
    mainMenu() {
      if (this.currentMenu) {
        let mid = this.currentMenu.substr(0, 2);
        let m = this.menus.find((m) => m.id === mid);
        if (m) {
          return m;
        }
      }
      return null;
    },
    subMenu() {
      if (
        this.currentMenu &&
        this.currentMenu.length >= 4 &&
        this.mainMenu &&
        this.mainMenu.subs &&
        this.mainMenu.subs.length
      ) {
        let mid = this.currentMenu.substr(0, 4);
        let m = this.mainMenu.subs.find((m) => m.id === mid);
        if (m) {
          return m;
        }
      }
      return null;
    },
  },
  methods: {
    changeLampVisible() {
      this.lampBtnVisible = !this.lampBtnVisible;
      console.log(this.lampBtnVisible);
    },
    generateOverlays(points) {
      let mks = this.markers,
        mapPoints = [];
      points.forEach((o, i) => {
        let existMarker = mks.find((m) => m._info.id == o.id);
        if (existMarker) {
          let point = new BMapGL.Point(o.lng, o.lat);
          existMarker.setPosition(point);
        } else {
          let icon;
          if (o.iconUrl) {
            icon = new BMapGL.Icon(o.iconUrl, new BMapGL.Size(19, 22));
          } else {
            icon = new BMapGL.Icon(
              "/images/types/" + o.overlayIcon + ".svg",
              new BMapGL.Size(40, 46)
            );
          }
          let point = new BMapGL.Point(o.lng, o.lat);
          let marker = new BMapGL.Marker(point, { icon });
          mapPoints.push(point);
          if (o.showTitle) {
            let label = new BMapGL.Label(o.name, {
              offset: new BMapGL.Size(-50, 18),
            });
            label.setStyle({
              backgroundColor: "transparent",
              width: "100px",
              height: "16px",
              textAlign: "center",
              border: "none",
              color: "#c0c4cc",
            });
            marker.setLabel(label);
          }
          marker.setZIndex(710 + i);
          marker.addEventListener("click", this.handleMarkerClick);
          marker._info = o;
          this.map.addOverlay(marker);
          mks.push(marker);
        }
      });

      if (this.currentMenuInstance.viewAll) {
        this.map.setViewport(mapPoints, {
          margins: [
            window.innerHeight * 0.2,
            window.innerWidth * 0.25,
            window.innerHeight * 0.2,
            window.innerWidth * 0.25,
          ],
        });
      } else {
        this.map.centerAndZoom(
          new BMapGL.Point(80.382155, 40.649907),
          this.currentMenuInstance.mapZoom || 18
        );
      }
    },
    loadPoints() {
      Promise.all([
        getEquipmentList(),
        getPreviewList(),
        getParkList(),
        getVillageList({ size: 1000 }),
        getLampAllList(),
        getMpcList(),
      ])
        .then((res) => {
          let store = [];

          let ecs = {
            lamp: {
              id: "lamp",
              name: "路灯",
              count: 0,
              successCount: 0,
              errorCount: 0,
              icon: "fa-solid fa-lightbulb",
            },
            cover: {
              id: "cover",
              name: "井盖",
              count: 0,
              icon: "fa-solid fa-life-ring",
            },
            trafficlight: {
              id: "trafficlight",
              name: "红绿灯",
              count: 0,
              icon: "fa-solid fa-traffic-light",
            },
            camera: {
              id: "camera",
              name: "监控",
              count: 0,
              icon: "fa-solid fa-video",
            },
          };
          (res[0] || []).forEach((d) => {
            if (d && d.equipType && d.equipType.typeCode) {
              let poi = d.gpsInfo
                  ? d.gpsInfo.replace(/\s/gi, "").split(",").map(Number)
                  : null,
                type = d.equipType.typeCode;
              if (type !== "lamp") {
                store.push({
                  ownerId: this.$uuid(),
                  id: d.equipmentId,
                  villageId: d.villageId,
                  name: d.equipmentName,
                  iconUrl: d.equipType ? d.equipType.iconUrl : null,
                  type,
                  lng: poi ? poi[0] : null,
                  lat: poi ? poi[1] : null,
                  originInfo: d,
                });
              }

              // if (type === "lamp") ecs.lamp.count++;
              if (type === "cover") ecs.cover.count++;
              if (type === "trafficlight") ecs.trafficlight.count++;
              if (
                type === "citycamera" ||
                type === "gascamera" ||
                type === "heatcamera" ||
                type === "sewagecamera"
              )
                ecs.camera.count++;
            }
          });

          (res[1] || []).forEach((d) => {
            if (d.geographicPosition) {
              store.push({
                ownerId: this.$uuid(),
                id: d.id,
                name: d.cameraName,
                overlayIcon: "jk",
                type: "camera",
                lng: d.geographicPosition.split(",")[0],
                lat: d.geographicPosition.split(",")[1].replace(/\s+/g, ""),
                cameraName: d.cameraName,
                cameraTypeName: d.cameraTypeName,
                channelTypeName: d.channelTypeName,
                cameraIndexCode: d.cameraIndexCode,
                address: d.address,
                originInfo: d,
              });
            }
          });

          (res[2] || []).forEach((d) => {
            let poi = d.coordinate
              ? d.coordinate.replace(/\s/gi, "").split(",").map(Number)
              : null;
            store.push({
              ownerId: this.$uuid(),
              id: d.id,
              name: d.pkname,
              overlayIcon: "tc",
              type: "parking",
              linkman: d.linkman,
              sn: d.sn,
              mobile: d.mobile,
              address: d.address,
              lng: poi ? poi[0] : null,
              lat: poi ? poi[1] : null,
              originInfo: d,
            });
          });

          (res[3] || []).forEach((d, inx) => {
            if (d.geographicPosition) {
              let poi = d.geographicPosition
                .replace(/\s/gi, "")
                .split(",")
                .map(Number);
              store.push({
                ownerId: this.$uuid(),
                id: d.id,
                name: d.name,
                overlayIcon: "xq",
                type: "residential",
                builderNumber: d.builderNumber,
                familyNumber: d.familyNumber,
                realFamilyNumber: d.realFamilyNumber,
                cameraIndexCode:
                  res[1] && res[1].length
                    ? res[1][inx % res[1].length].cameraIndexCode
                    : null, //为小区临时绑定一个摄像头
                estateId: d.estateId,
                lng: poi ? poi[0] : null,
                lat: poi ? poi[1] : null,
                originInfo: d,
              });
            }
          });
          (res[4] || []).forEach((d, inx) => {
            if (d.gpsInfo) {
              let poi = d.gpsInfo.replace(/\s/gi, "").split(",").map(Number);
              store.push({
                ownerId: this.$uuid(),
                id: d.lampKey,
                name: d.lampKey,
                iconUrl: this.lampIcons[d.status || 0],
                status: d.status,
                type: "lamp",
                isMpc: false,
                address: d.address,
                lng: poi ? poi[0] : null,
                lat: poi ? poi[1] : null,
                originInfo: d,
              });
            }
            ecs.lamp.count++;
            if (d.status == 1 || d.status == 0) {
              ecs.lamp.successCount++;
            } else if (d.status == 3 || d.status == 2) {
              ecs.lamp.errorCount++;
            }
          });
          (res[5] || []).forEach((d, inx) => {
            if (d.gpsInfo) {
              let poi = d.gpsInfo.replace(/\s/gi, "").split(",").map(Number);
              store.push({
                ownerId: this.$uuid(),
                id: d.dataKey,
                name: d.dataKey,
                iconUrl: "./images/types/mpc.svg",
                type: "lamp",
                isMpc: true,
                address: d.address,
                lng: poi ? poi[0] : null,
                lat: poi ? poi[1] : null,
                originInfo: d,
              });
            }
          });
          this.equipmentCounts = ecs;
          this.store = store;
        })
        .finally(() => {
          this.handleMenuChange(this.menus[0]);
        });
    },
    // getDataList() {
    //   getEquipmentList().then((res) => {
    //     res.forEach((r) => {
    //       r.name = r.equipmentName;
    //       r.id = r.equipmentId;
    //       r.iconUrl = r.equipType ? r.equipType.iconUrl : null;
    //       r.type = "equipment";
    //       r.lng = r.gpsInfo.split(",")[0];
    //       r.lat = r.gpsInfo.split(",")[1].replace(/\s+/g, "");
    //       r.point = null;
    //     });
    //     this.overview.types[1].store = res;
    //   });
    // },
    createMap() {
      let map = new BMapGL.Map("main_dashboard_map_container");
      map.centerAndZoom(new BMapGL.Point(80.382155, 40.649907), 17);
      map.enableScrollWheelZoom(true);
      // map.setMinZoom(14);
      // map.setMapType(BMAP_EARTH_MAP);
      // map.disableTilt();
      // map.setTilt(0);
      // map.setHeading(60);
      map.setDisplayOptions({
        poiIcon: false,
        // poi: false,
        skyColors: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"],
      });
      map.addEventListener("moving", this.handleMapListener);
      map.addEventListener("zoomend", this.handleMapListener);
      // let zoomor = new BMapGL.ZoomControl({
      //   anchor: BMapGL.ControlAnchor.BOTTOM_RIGHT,
      //   offset: new BMapGL.Size(52, 135),
      // });
      // let navi3DCtrl = new BMapGL.NavigationControl3D({
      //   anchor: BMapGL.ControlAnchor.BOTTOM_RIGHT,
      //   offset: new BMapGL.Size(35, 26),
      // }); // 添加3D控件
      // map.addControl(zoomor);
      // map.addControl(navi3DCtrl);
      map.setMapStyleV2({
        styleJson: this.mode === "day" ? day : night,
      });
      this.map = map;

      this.loadPoints();
    },

    switchMode() {
      this.mode = this.mode === "day" ? "night" : "day";
      this.handleModeChange();
    },
    handleMapListener() {
      if (this.detail.marker && this.detail.visible) {
        let dom = this.detail.marker.domElement;
        let l = dom.offsetLeft,
          t = dom.offsetTop,
          w = dom.offsetWidth,
          h = dom.offsetHeight;
        if (l > 5000) l = -100;
        if (l < -5000) l = window.innerWidth;
        this.detail.line.points =
          l +
          w +
          "," +
          t +
          " " +
          this.detail.endX +
          ",180" +
          " " +
          this.detail.endX +
          ",185";
      }
    },
    handleModeChange() {
      if (this.map) {
        this.map.setMapStyleV2({
          styleJson: this.mode === "day" ? day : night,
        });
        let color = this.mode === "day" ? "#303133" : "#c0c4cc";
        if (this.markers && this.markers.length) {
          this.markers.forEach((m) => {
            let label = m.getLabel();
            label.setStyle({ color });
          });
        }
      }
    },
    handleMenuChange(m) {
      if (m && m.id && m.id !== this.currentMenu) {
        this.detail.visible = false;
        this.detail.current = null;
        if (m.link) {
          window.open(m.link);
        } else {
          this.currentMenu = m.id;
          if (m.subs && m.subs.length) {
            this.handleMenuChange(m.subs[0]);
            return false;
          }

          this.map.clearOverlays();
          if (m.type) {
            console.log(m.type);
            this.currentType = m.type;
            this.markers = [];
            let ds = this.store.filter((d) => d.type === m.type);
            if (m.filter && typeof m.filter === "function") {
              ds = ds.filter(m.filter);
            }
            this.currentMenuInstance = m;
            this.generateOverlays(ds);

            if (m.defaultShow) {
              let marker = this.markers.find(
                (o) => o._info.id === m.defaultShow
              );
              if (marker) {
                this.handleMarkerClick({
                  currentTarget: marker,
                });
              }
            }
          }
        }
      }
    },
    doResetMarkerIcon() {
      if (this.detail.current && this.detail.marker) {
        let icon;
        if (this.detail.current.iconUrl) {
          icon = new BMapGL.Icon(
            this.detail.current.iconUrl,
            new BMapGL.Size(19, 22)
          );
        } else {
          icon = new BMapGL.Icon(
            "/images/types/" + this.detail.current.overlayIcon + ".svg",
            new BMapGL.Size(40, 46)
          );
        }

        this.detail.marker.setIcon(icon);
      }
    },

    handlePageClose() {
      this.doResetMarkerIcon();
      this.detail.current = null;
    },

    handleMarkerClick(e) {
      this.doResetMarkerIcon();
      let d = e.currentTarget._info;
      if (d) {
        if (d.type == "lamp") {
          this.lampvisible = true;
          this.lampdata = d;
        } else {
          let icon;
          if (d.iconUrl) {
            icon = new BMapGL.Icon(d.iconUrl, new BMapGL.Size(19, 22));
          } else {
            icon = new BMapGL.Icon(
              "/images/types/" + d.overlayIcon + ".checked.svg",
              new BMapGL.Size(40, 46)
            );
          }
          e.currentTarget.setIcon(icon);
          this.detail.endX = window.innerWidth - 530;
          this.detail.marker = e.currentTarget;
          this.detail.current = d;
          if (this.detail.excludeTypes.indexOf(d.type) < 0) {
            this.detail.visible = true;
            this.handleMapListener();
          }
        }
      }
    },
    handleWindownSizeChange() {
      if (this.masker.timer) clearTimeout(this.masker.timer);
      this.masker.visible = false;
      let wr = Math.round((window.innerWidth / 1920) * 10000) / 10000,
        hr = Math.round((window.innerHeight / 1080) * 10000) / 10000;

      // bimgPath = "M-0.146 92.074l0 918.49c445.312,-71.261 1476.033,-70.272 1920.844,0l0 -918.491c-446.813,71.263 -1474.531,67.304 -1920.844,0.001z";
      let bimgPath = `M-0 ${92 * hr}l0 ${950 * hr}c${445 * wr},-${70 * hr} ${
        1475 * wr
      },-${70 * hr} ${1920 * wr},0l0 -${960 * hr}c-${445 * wr},${70 * hr} -${
        1475 * wr
      },${70 * hr} -${1920 * wr},0z`;
      // console.log(bimgPath);

      let bimg =
        btoa(`<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" width="${
          1920 * wr
        }" height="${1080 * hr}"
  style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 ${1920 * wr} ${
          1080 * hr
        }" xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
  <defs>
    <linearGradient x1="0" y1="0" x2="1" y2="0" id="_masker_panel">
      <stop stop-color="#000032" offset="0" />
      <stop stop-color="#00003200" offset="0.15" />
      <stop stop-color="#00003200" offset="0.85" />
      <stop stop-color="#000032" offset="1" />
    </linearGradient>
  </defs>
  <path
    d="${bimgPath}"
    fill="url(#_masker_panel)" />
</svg>`);
      // console.log(bimg);
      this.masker.backgroundImage = `url(data:image/svg+xml;base64,${bimg})`;

      // <path d="M-0.146 80.196l0 11.878c446.313,67.303 1474.031,71.262 1920.844,-0.001l0 -11.877c-445.148,79.925 -1475.696,79.925 -1920.844,0zm0 13.867l0 1.74c446.313,67.302 1474.031,71.261 1920.844,-0.002l0 -1.739c-446.813,71.263 -1474.531,67.304 -1920.844,0.001z" />
      this.masker.top = `M-0 ${80 * hr}l0 ${12 * hr}c${446 * wr},${67 * hr} ${
        1475 * wr
      },${71 * hr} ${1920 * wr},-0l0 -${12 * hr}c-${446 * wr},${80 * hr} -${
        1475 * wr
      },${80 * hr} -${1920 * wr},0zm0 ${14 * hr}l0 ${2 * hr}c${446 * wr},${
        67 * hr
      } ${1475 * wr},${71 * hr} ${1920 * wr},-0l0 -${2 * hr}c-${446 * wr},${
        71 * hr
      } -${1475 * wr},${67 * hr} -${1920 * wr},0z`;

      // console.log(this.masker.top);

      // <path d="M-0.146 1010.564l0 11.877c445.148,-79.925 1475.696,-79.925 1920.844,0l0 -11.877c-444.811,-70.272 -1475.532,-71.261 -1920.844,0zm0 -3.425l0 1.657c445.312,-71.262 1476.033,-70.272 1920.844,0l0 -1.657c-444.811,-70.273 -1475.532,-71.262 -1920.844,0z" />
      this.masker.bottom = `M-0 ${1010 * hr}l0 ${12 * hr}c${445 * wr},-${
        80 * hr
      } ${1475 * wr},-${80 * hr} ${1920 * wr},0l0 -${12 * hr}c-${445 * wr},-${
        70 * hr
      } -${1475 * wr},-${70 * hr} -${1920 * wr},0zm0 -${3.425 * hr}l0 ${
        2 * hr
      }c${445 * wr},-${70 * hr} ${1475 * wr},-${70 * hr} ${1920 * wr},0l0 -${
        2 * hr
      }c-${445 * wr},-${70 * hr} -${1475 * wr},-${70 * hr} -${1920 * wr},0z`;

      // console.log(this.masker.bottom);

      // M-0.345 60.105c445.166,-80.14 1475.528,-80.139 1920.69,0.001l0 57.894 -1920.69 0 0 -57.895z
      this.masker.menu = `M-0 ${60 * hr}c${445 * wr},-${80 * hr} ${
        1475 * wr
      },-${80 * hr} ${1920 * wr},0l0 ${60 * hr} -${1920 * wr} 0 0 -${60 * hr}z`;

      // console.log(this.masker.menu);

      this.masker.menuHeight = 120 * hr;

      this.masker.timer = setTimeout(() => {
        this.masker.visible = true;
      }, 300);
    },

    loadWeather() {
      getWeather({
        key: "3b9208ebd00448afea39d10a40e135ad",
        city: "652928",
      }).then((res) => {
        if (res && res.lives && res.lives.length) {
          this.weather.temperature = res.lives[0].temperature;
          this.weather.humidity = res.lives[0].humidity;
        }
      });
    },
  },
  mounted() {
    window.onresize = this.handleWindownSizeChange;
    window.cameraUrls = {};
    this.handleWindownSizeChange();
    this.createMap();

    this.loadWeather();

    // this.startMenuLoop();
    setTimeout(() => {
      this.erath = false;
    }, 5000);
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
};
</script>

<style lang="scss">
@keyframes mode-btn-kf {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
@keyframes erath {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(2.5);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
#main_dashboard_map_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.app-dashboard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .erath {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url("/images/erath.gif");
    animation: erath 5s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .anchorBL {
    display: none;
  }

  .BMap_cpyCtrl {
    display: block;
    inset: auto auto 20px 30px !important;
  }

  @keyframes _masker_border_top_animate {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  @keyframes _masker_border_bottom_animate {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }

  > .panel-masker {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;

    &-border-top {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 201;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      background-size: 300% 100%;
      background-image: linear-gradient(
        to right,
        #2e90f5 0%,
        #2e90f500 16.6%,
        #2e90f5 33.3%,
        #2e90f500 50%,
        #2e90f5 66.7%,
        #2e90f500 83.3%,
        #2e90f5
      );
      clip-path: url(#_masker_border_top);
      animation: _masker_border_top_animate 5s infinite linear;
    }

    &-border-bottom {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 201;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      background-size: 300% 100%;
      background-image: linear-gradient(
        to right,
        #2e90f5 0%,
        #2e90f500 16.6%,
        #2e90f5 33.3%,
        #2e90f500 50%,
        #2e90f5 66.7%,
        #2e90f500 83.3%,
        #2e90f5
      );
      clip-path: url(#_masker_border_bottom);
      animation: _masker_border_bottom_animate 5s infinite linear;
    }
  }

  .menus {
    pointer-events: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 202;
    clip-path: url(#_masker_menu);
    background: linear-gradient(to bottom, #000032cc, #000032);

    > .main-menus {
      &:before {
        display: block;
        width: 1px;
        background: linear-gradient(to bottom, transparent, #2e90f566);
        content: "";
      }

      > .menu-item {
        font-size: 18px;
        font-weight: bold;
        min-width: 150px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 8px;
        cursor: pointer;
        color: #78eefc;
        transition: all 0.3s;
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background: linear-gradient(to bottom, transparent, #2e90f566);
          content: "";
        }

        &:hover {
          background: linear-gradient(to bottom, transparent, #19297499);
        }

        &.checked {
          background: linear-gradient(to bottom, transparent, #2e90f5);
          color: #ffffff;

          > i {
            font-weight: bold;
          }
        }
      }
    }
    > .sub-menus {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      background-color: #000032;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        content: "";
        background: linear-gradient(
          to right,
          transparent,
          #2e90f5,
          transparent
        );
      }
      &:before {
        display: block;
        width: 1px;
        content: "";
        background: linear-gradient(to top, transparent, #2e90f566);
      }

      > .menu-item {
        padding: 15px 0;
        min-width: 150px;
        font-size: 16px;
        line-height: 1;
        color: white;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        color: #78eefc;
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background: linear-gradient(to top, transparent, #2e90f566);
          content: "";
        }

        > i {
          margin-right: 5px;
        }

        &:hover {
          background: linear-gradient(to top, transparent, #19297499);
        }

        &.checked {
          background: linear-gradient(to top, transparent, #2e90f5);
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  > .box {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    > .gap {
      &:before {
        height: 17px;
        background-color: #000032;
        content: "";
      }
      &:after {
        height: 10px;
        background-color: #000032;
        content: "";
      }
    }

    .top-left,
    .bottom-left,
    .top-right,
    .bottom-right,
    .top-center-left,
    .top-center-right,
    .bottom-center-left,
    .bottom-center-right {
      background-repeat: no-repeat;
      background-position: center center;
    }

    .top-left {
      width: 100px;
      height: 100px;
      background-image: url("/images/dashboard-box-tl.png");
    }

    .top-right {
      width: 100px;
      height: 100px;
      background-image: url("/images/dashboard-box-tr.png");
    }

    .bottom-left {
      width: 100px;
      background-image: url("/images/dashboard-box-bl.png");
      height: 93px;
    }

    .bottom-right {
      width: 100px;
      background-image: url("/images/dashboard-box-br.png");
      height: 93px;
    }

    .top-center-right {
      width: 200px;
      height: 20px;
      background-image: url("/images/dashboard-box-tmr.png");
    }

    .bottom-center-left {
      width: 200px;
      height: 20px;
      background-image: url("/images/dashboard-box-bml.png");
    }

    .bottom-center-right {
      width: 200px;
      height: 20px;
      background-image: url("/images/dashboard-box-bmr.png");
    }

    .top-center-left {
      width: 200px;
      height: 20px;
      background-image: url("/images/dashboard-box-tml.png");
    }

    .center-left {
      width: 100px;
      background-repeat: repeat-y;
      background-position: center center;
      background-image: url("/images/dashboard-box-ml.png");
    }

    .center-right {
      width: 100px;
      background-repeat: repeat-y;
      background-position: center center;
      background-image: url("/images/dashboard-box-mr.png");
    }

    .top-center-gap {
      min-width: 100px;
      height: 20px;
      background-repeat: repeat-x;
      background-position: center center;
      background-image: url("/images/dashboard-box-tmb.png");
    }

    .bottom-center-gap {
      height: 20px;
      background-repeat: repeat-x;
      background-position: center center;
      background-image: url("/images/dashboard-box-bmb.png");
    }

    .logo {
      margin-top: 10px;
      width: 800px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("/images/dashboard-title.png");
    }

    .sub-menus {
      margin: 50px 0;
      pointer-events: auto;
      background-color: #2e90f5;
      border-radius: 50px;
      overflow: hidden;

      > .menu-item {
        line-height: 36px;
        padding: 0 30px;
        cursor: pointer;
        color: white;
        font-size: 16px;
        // border-radius: 50px;
        // border: transparent solid 2px;

        &.checked {
          border-color: #2e90f5;
          background: linear-gradient(to bottom, white, #e2e2e2);
          color: #000032;
          z-index: 10;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
          font-weight: bold;
        }
      }
    }
  }
  .lamp_show {
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    right: 50px;
    bottom: 50px;
    z-index: 999;
    background-color: #236dc5;
    color: white;
    font-weight: bold;
  }
  .environment-item {
    padding-right: 20px;
    margin-right: 20px;
    border-right: rgba(120, 238, 252, 0.3) solid 1px;
    line-height: 1;
    > i {
      display: block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background-color: rgb(120, 238, 252);
      color: #000032;
      font-size: 28px;
      border-radius: 50%;
      margin-right: 5px;
    }
    .name {
      opacity: 0.8;
      font-size: 16px;
    }
    .value {
      font-size: 28px;
      margin-top: 5px;
    }
  }

  > .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 199;
    padding: 20px 20px 120px 20px;
    background: linear-gradient(to bottom, #000032 50%, transparent);
    pointer-events: none;
  }

  .operator {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 20px;
    height: 80px;
    z-index: 998;
    pointer-events: none;
    // background: linear-gradient(to top, #000032, transparent);

    > .operat-btn {
      pointer-events: auto;
      position: absolute;
      left: 20px;
      top: 0;
      z-index: 999;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      > .back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("/images/dashboard-day-mode.png");
        animation: mode-btn-kf 2s infinite linear;
      }

      > .front {
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;

        > i {
          font-size: 28px;
        }

        &.day {
          background-color: #fff;
          box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.2);
          color: #606266;
        }

        &.night {
          background-color: #192974;
          box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.6);
          color: #c0c4cc;
        }
      }
    }
  }

  .detail-line {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 980;
  }

  * {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #091220;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #78f0fd33;
      border-radius: 3px;
      border: #091220 solid 2px;
    }
  }
}

.app-dashboard-analy {
  position: fixed;
  z-index: 298;
  top: 0;
  bottom: 0;
  left: 14px;
  right: 14px;
  padding-top: 7.5%;
  pointer-events: none;
  transition: all 0.3s;
  color: white;

  .page-title {
    position: absolute;
    left: 0px;
    top: 17px;
    background: linear-gradient(to left, #0262c5, #2e90f5 50%);
    border-radius: 0 5px 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    pointer-events: auto;
    box-sizing: border-box;
    line-height: 1;

    > ._text {
      padding: 10px;
      font-size: 22px;
      font-weight: bold;
      text-shadow: 1px 1px 5px #000032;
    }

    > ._close {
      padding: 10px;
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
    }
  }

  > .overview {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .panel {
    position: relative;
    z-index: 10;
    pointer-events: auto;
    overflow: hidden;

    > div {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: initial;

      &.flex {
        flex: 1;
      }
    }
  }

  .counts {
    width: 720px;
    margin: 0 20px 20px 20px;
    position: relative;
    z-index: 10;
    background-color: #09122033;
  }

  .roll-item {
    margin: 0 10px 10px 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #2e90f515;
    line-height: 1.5;
    transition: all 0.3s;

    &:hover {
      background-color: #2e90f533;
    }
    ._title {
      opacity: 0.8;
    }
  }
}
</style>