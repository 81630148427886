<template>

  <div class="app-dashboard-analy h" :class="{ modal: visible }">
    <div class="v flex">
      <transition enter-active-class="animated faster fadeInLeft" leave-active-class="animated faster fadeOutLeft">
        <div class="panel v flex" style="width: 33.3%;" v-if="visible">
          <panel title="红绿灯数量">
            <div class="h">
              <number-view :value="counts.trafficlight.count" title="总数" icon="fa-solid fa-traffic-light" style="width: 30%;" />
              <number-view :value="4" title="正常" icon="fa-solid fa-circle-check fc-s" style="width: 30%;" />
              <number-view :value="0" title="异常" icon="fa-solid fa-triangle-exclamation fc-e" class="flex" />
            </div>
          </panel>

          <panel title="红绿灯维护记录" class="flex">
            <seamless-scroll :data="infoList" :class-option="classOption">
              <div class="roll-item" v-for="(item, index) in infoList" :key="index">
                <div>
                  <span class="_title">地址&emsp;</span>
                  {{ item.address }}
                </div>
                <div>
                  <span class="_title">名称&emsp;</span>
                  {{ item.name }}
                </div>
                <div>
                  <span class="_title">时间&emsp;</span>
                  {{ item.time }}
                </div>
              </div>
            </seamless-scroll>
          </panel>
        </div>
      </transition>

      <repair :visible="visible" style="height: 33%; margin-top: -10px;" />
    </div>

    <transition enter-active-class="animated faster fadeInRight" leave-active-class="animated faster fadeOutRight">
      <div class="panel v" style="width: 25%;" v-if="visible">
        <panel title="红绿灯故障率分析" style="height: 40%;">
          <red-community />
        </panel>

        <panel title="红绿灯预警记录" class="flex">
          <seamless-scroll :data="infoList" :class-option="classOption">
            <div class="roll-item" v-for="(item, index) in infoList" :key="index">
              <div>
                <span class="_title">地址&emsp;</span>
                {{ item.address }}
              </div>
              <div>
                <span class="_title">名称&emsp;</span>
                {{ item.name }}
              </div>
              <div>
                <span class="_title">时间&emsp;</span>
                {{ item.time }}
              </div>
              <div>
                <span class="_title">等级&emsp;</span>
                {{ item.level }}
              </div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </transition>
  </div>
</template>

<script>
import RedCommunity from "./charts/red-community";
import Repair from "./repair/index";

export default {
  data() {
    return {
      infoList: [
        {
          id: 1,
          address: "阿瓦提团结东路12号",
          name: "团结东路一号红绿灯",
          time: "2022-02-22 12:23:20",
          level: "较急",
        },
        {
          id: 2,
          address: "阿瓦提多浪东路26号",
          name: "多浪东路二号红绿灯",
          time: "2022-01-22 10:53:20",
          level: "一般",
        },
        {
          id: 3,
          address: "阿瓦提团结东路50号",
          name: "团结东路三号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "一般",
        },
        {
          id: 4,
          address: "阿瓦提团结东路65号",
          name: "团结东路四号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "一般",
        },
        {
          id: 5,
          address: "阿瓦提团结东路26号",
          name: "团结东路五号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "一般",
        },
        {
          id: 6,
          address: "阿瓦提团结东路22号",
          name: "团结东路六号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "一般",
        },
        {
          id: 7,
          address: "阿瓦提团结东路63号",
          name: "团结东路七号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "一般",
        },
        {
          id: 8,
          address: "阿瓦提团结东路129号",
          name: "团结东路八号红绿灯",
          time: "2022-02-18 08:23:20",
          level: "紧急",
        },
      ],
      classOption: {
        step: 0.85, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000,
      },
    };
  },
  components: {
    Repair,
    RedCommunity,
  },
  props: {
    visible: Boolean,
    counts:Object
  },
};
</script>