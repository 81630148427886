<template>
  <div>
    <camera-video :code="data.cameraIndexCode" single />

    <div class="padding-20-0">
      监控点名称：{{ data.cameraName }}
      <br />
      监控点类型：{{ data.cameraTypeName }}
      <br />
      通道类型：{{ data.channelTypeName }}
      <br />
      所在位置：{{ data.address }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
};
</script>