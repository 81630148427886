<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    init() {
      let chart = echarts.init(this.$el, "owner");
      var option = {
        grid: {
          top: 20,
          right: 0,
          bottom: 20,
          left: 0,
          containLabel: true,
        },

        tooltip: {
          show: true,
          trigger: "axis",
          formatter: "{b0}<br/>{a0}: {c0}%",
        },

        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
            show: true,
          },
        ],
        series: [
          {
            name: "监控故障率",
            data: [5.2, 5.1, 4.9, 3.4, 2.8, 3, 2.9, 1.2, 3.6, 3.1, 2.3, 1.4],
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 11,
                  },
                  formatter: "{c0}%",
                },
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
};
</script>