<template>
  <div class="h">
    <div class="no-flex" ref="chart" style="width: 180px"></div>
    <div class="flex sa-c" style="margin: 0 20px; font-size: 14px;">
      <div class="h" style="opacity: 0.6; line-height: 2">
        <div style="width: 15px"></div>
        <div class="flex">年份</div>
        <div style="width: 50px; text-align: right">数量</div>
        <div style="width: 70px; text-align: right">面积</div>
      </div>
      <div v-for="(o, i) in store" :key="o.name" class="h" style="line-height: 2">
        <div class="sa-c" style="width: 8px; height: 8px; margin-right: 7px" :style="{ backgroundColor: colors[i] }"></div>
        <div class="flex">{{ o.name }}</div>
        <div style="width: 50px; text-align: right">{{ o.value }}</div>
        <div style="width: 70px; text-align: right">{{ o.totleMeasure }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getYearVillage } from "@/api/data";
export default {
  data() {
    return {
      colors: [],
      store: [],
    };
  },
  methods: {
    getYear() {
      this.store = [];
      getYearVillage().then((res) => {
        if (res.length > 0) {
          var temp = JSON.parse(JSON.stringify(res)).splice(0, 6);
          temp.map((r) => {
            this.store.push({
              name: r.name,
              value: r.villageCount || 0,
              totleMeasure: r.totleMeasure || 0,
            });
          });
          this.init();
        }
      });
    },
    init() {
      let chart = echarts.init(this.$refs.chart, "owner");
      let colors = chart._theme.color;
      this.colors = colors;

      var option = {
        tooltip: {},
        series: [
          {
            type: "pie",
            name: "年份",
            radius: ["30%", "80%"],
            // roseType: "radius",
            data: this.store,
            minAngle: 30,
            itemStyle: {
              color: (p) => {
                let c = colors[p.dataIndex];
                return new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  { offset: 0, color: c + "ee" },
                  { offset: 1, color: c + "99" },
                ]);
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.getYear();
  },
};
</script>