<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      chart2: null,
    };
  },
  methods: {
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      this.chart2.clear();
      this.chart2.setOption({
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{c0}%",
        },
        xAxis: {
          type: "category",
          data: [
            "2021-06",
            "2021-07",
            "2021-08",
            "2021-09",
            "2021-10",
            "2021-11",
            "2021-12",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [7.67, 9.65, 8.35, 8.58, 6.54, 6.53, 5.42],
            type: "line",
            smooth: true,
            areaStyle: {},
            barMaxWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#C0C4CC",
                    fontSize: 12,
                  },
                  formatter: "{c0}%",
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>