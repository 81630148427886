<template>
  <div class="pwm"></div>
</template>

<script>
export default {
  data() {
    return {
      chart2: null,
      num: "12",
    };
  },
  props: {
    p: Number,
  },
  methods: {
    init() {
      this.chart2 = echarts.init(this.$el, "owner");

      this.chart2.clear();
      this.chart2.setOption({
        series: [
          {
            type: "gauge",
            max: 2500, //最大值
            progress: {
              show: true,
              width: 5,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 3,
              color: "#999",
              fontSize: 10,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 5,
              itemStyle: {
                borderWidth: 10,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: 12,
              offsetCenter: [0, "70%"],
            },
            data: [
              {
                value: this.p > 2500 ? 0 : this.p,
              },
            ],
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.pwm {
  width: 150px;
  height: 150px;
}
</style>