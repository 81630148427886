<template>
  <!-- 常住人口和流动人口统计 -->
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    init() {
      let chart = echarts.init(this.$el, "owner");
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "10%", //图例距离左的距离
          y: "top", //图例上下居中
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          data: ["常住人口", "流动人口"],
        },

        series: [
          {
            name: "常住人口和流动人口统计",
            type: "pie",
            radius: ["35%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#78eefc",
              borderWidth: 1,
            },
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}\n{c}",
              color: "#ffffff",
              textBorderWidth: 2,
              textBorderColor: "rgba(0,0,0,.6)",
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 800, name: "常住人口", itemStyle: { color: "#409EFF" } },
              { value: 25, name: "流动人口", itemStyle: { color: "#E6A23C" } },
            ],
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
};
</script>