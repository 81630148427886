module.exports = {
  /**
   * @description AES加密秘钥
   */
  aesKey: 'kem73x8T',
  /**
   * @description token key
   */
  tokenKey: 'GPH-Token',
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
};