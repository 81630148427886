// autoprefixer: > 5%; last 2 Chrome versions; not ie 6-9, out: false

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Cookies from 'js-cookie'
import 'highlight.js/styles/atom-one-dark.css'


import "@/assets/common.scss"
import "@/assets/base.scss"
import './assets/styles/element-variables.scss'

import request from "@/utils/request"
import axios from 'axios'
import Support from "@/utils/support"

import FileUploader from '@/components/FileUploader'
import Panel from '@/components/Panel'
import NumberView from '@/components/NumberView'
import CameraVideo from '@/components/CameraVideo'
import Dot from '@/components/Dot'
import SeamlessScroll from "vue-seamless-scroll";

Vue.use(Support)
Vue.component("file-uploader", FileUploader)
Vue.component("panel", Panel)
Vue.component("number-view", NumberView)
Vue.component("dot", Dot)
Vue.component("camera-video", CameraVideo)
Vue.component("seamless-scroll", SeamlessScroll)
Vue.config.productionTip = false

require("promise.prototype.finally").shim();

Vue.prototype.$http = request;
Vue.prototype.$https = axios;
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')