<template>
  <div></div>
</template>

<script>
import { getBigVillage } from "@/api/data";
export default {
  data(){
    return{
      xData:[],
      measurData:[],
      householdsData:[]
    }
  },
  methods: {
    //获取超大小区分析
    getBig() {
      this.xData=[]
      this.measurData=[]
      this.householdsData=[]
      getBigVillage().then((res) => {
        if(res.length>0){
          var temp = JSON.parse(JSON.stringify(res)).splice(0,6)
          temp.map(r=>{
            this.xData.push(r.name)
            this.measurData.push(r.measure || 0)
            this.householdsData.push(r.familyNumber ||0)
          })
          this.init()
        }
      });
    },
    init() {
      let chart = echarts.init(this.$el, "owner");

      var option = {
        grid: {
          top: 20,
          right: 10,
          bottom: 10,
          left: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          data: this.xData
        },
        yAxis: [{ splitNumber: 2 }, { splitNumber: 3 }],
        series: [
          {
            name: "面积（m²）",
            type: "bar",
            data: this.measurData,
            barMaxWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },
          },
          {
            name: "户数",
            type: "bar",
            yAxisIndex: 1,
            barMaxWidth: 20,
            data: this.householdsData,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#e5764acc" },
                  { offset: 1, color: "#e5764a00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#e5764a" },
                  { offset: 1, color: "#e5764a33" },
                ]),
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.getBig();
  },
};
</script>