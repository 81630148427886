<template>
  <div>
    <div class="h padding-20-0">
      <number-view
        :value="parseFloat(data.builderNumber) || 0"
        title="楼栋数1111"
        icon="fa-solid fa-building"
        style="width: 30%"
      />
      <number-view
        :value="parseFloat(data.familyNumber) || 0"
        title="总户数"
        icon="fa-solid fa-solid fa-users"
        style="width: 30%"
      />
      <number-view
        :value="parseFloat(data.realFamilyNumber) || 0"
        title="入住数"
        icon="fa-solid fa-elevator"
        class="flex"
      />
    </div>

    <div class="padding-10-20 fs-large">建设时间：1995年</div>
    <div class="padding-10-20 c h fs-large">
      <div>监控数量：19</div>
      <div class="flex"></div>
      <div>车位数量：25</div>
    </div>
    <div class="padding-10-20 c h fs-large">
      <div>路灯数量：10</div>
      <div class="flex"></div>
      <div>入住率：100%</div>
    </div>
    <div class="padding-10-20 fs-large">
      物业公司：{{ data.estateName || "" }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style>
</style>