<template>
  <div
    class="app-dashboard-analy app-dashboard-analy-camera h sb"
    :class="{ modal: visible }"
  >
    <transition
      enter-active-class="animated faster fadeInLeft"
      leave-active-class="animated faster fadeOutLeft"
    >
      <div class="panel v" style="width: 25%" v-if="visible">
        <panel title="监控" class="no-flex">
          <div class="h">
            <number-view
              :value="counts.camera.count"
              title="监控数量"
              icon="el-icon-video-camera-solid"
              class="flex"
            />
            <number-view
              :value="3"
              title="监控掉线"
              icon="el-icon-video-camera"
              class="flex"
            />
            <number-view
              :value="14"
              suffix="%"
              title="监控故障率"
              icon="el-icon-video-camera-solid"
              class="flex"
            />
          </div>
        </panel>
        <panel title="不文明行为信息" class="flex">
          <seamless-scroll
            :data="incivilizationData"
            :class-option="classOption"
          >
            <div
              class="roll-item"
              v-for="(item, index) in incivilizationData"
              :key="index"
            >
              <div>
                <span class="_title">地点&emsp;</span>
                {{ item.address }}
              </div>
              <div>
                <span class="_title">行为&emsp;</span>
                {{ item.type }}
              </div>
            </div>
          </seamless-scroll>
        </panel>
      </div>
    </transition>
    <transition
      enter-active-class="animated faster fadeInRight"
      leave-active-class="animated faster fadeOutRight"
    >
      <div class="panel v r" style="width: 700px" v-if="visible">
        <panel>
          <div class="h sa wrap">
            <div class="camera-item no-flex" v-for="c in cameras" :key="c.id">
              <div class="_title"></div>
              <camera-video
                :code="c.cameraIndexCode"
                :width="320"
                :height="180"
              />
            </div>
          </div>
        </panel>
      </div>
    </transition>
  </div>
</template>

<script>
import CameraView from "./charts/camera-view";

import CameraNew from "./charts/camera-new";
import { getPreview } from "@/api/data";
export default {
  data() {
    return {
      cameras: [],
      cameraLoaded: false,
      incivilizationData: [
        {
          id: 1,
          address: "花园西路",
          type: "骑摩托车不戴头盔",
        },
        {
          id: 2,
          address: "建设中路",
          type: "新N68D58违章停车",
        },
        {
          id: 3,
          address: "建设中路",
          type: "机动车不避让行人",
        },
        {
          id: 4,
          address: "光明中路",
          type: "机动车不避让行人",
        },
        {
          id: 5,
          address: "阿瓦提县体育场",
          type: "醉酒者躺路边睡觉",
        },
      ],
      classOption: {
        step: 0.65,
        limitMoveNum: 1,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 3000,
      },
    };
  },
  components: {
    CameraView,
    CameraNew,
  },
  props: {
    visible: Boolean,
    counts: Object,
  },
  watch: {
    visible: "loadCameras",
  },
  methods: {
    loadCameras() {
      if (!this.cameraLoaded && this.visible) {
        getPreview({ type: 2, page: 0, size: 6 }).then((res) => {
          if (res && res.content) {
            res.content.sort((a, b) => {
              return a.cameraName > b.cameraName ? 1 : -1;
            });
            this.cameras = res.content;
            this.cameraLoaded = true;
          }
        });
      }
    },
  },
};
</script>