<template>
  <div></div>
</template>

<script>
import { getReportLine } from "@/api/data";
export default {
  data() {
    return {
      xData: [],
      accepeData: [],
      submitData: [],
    };
  },
  methods: {
    getLine() {
      this.xData = [];
      this.accepeData = [];
      this.submitData = [];
      getReportLine().then((res) => {
        if (res.length > 0) {
          var temp = JSON.parse(JSON.stringify(res)).splice(0, 6);
          temp.map(r=>{
            this.xData.push(r.title)
            this.accepeData.push(r.acceptNumber)
            this.submitData.push(r.submitNumber)
          })
          this.init();
        }
      });
    },
    init() {
      let chart = echarts.init(this.$el, "owner");

      var option = {
        grid: {
          top: 20,
          right: 0,
          bottom: 20,
          left: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          data: this.xData,
        },
        yAxis: [{ splitNumber: 2 }, { splitNumber: 3 }],
        series: [
          {
            name: "已处理",
            type: "bar",
            data: this.accepeData,
            barMaxWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fdcc" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#78f0fd" },
                  { offset: 1, color: "#78f0fd33" },
                ]),
              },
            },
          },
          {
            name: "已提交",
            type: "bar",
            data: this.submitData,
            barMaxWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#78f0fd00" },
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F53908" },
                  { offset: 1, color: "#e5764a33" },
                ]),
              },
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
  mounted() {
    this.getLine();
  },
};
</script>