<template>
  <div class="app-dashboard-detail-alarm">
    <div class="title">
      <i class="fa-solid fa-link"></i>
      <span>链接告警</span>
    </div>
    <div class="title">
      <i class="fa-solid fa-temperature-half"></i>
      <span>温度告警</span>
    </div>
    <div class="title">
      <i class="fa-solid fa-arrow-right-to-bracket"></i>
      <span>RF入告警</span>
    </div>
    <div class="title">
      <i class="fa-solid fa-arrow-right-from-bracket"></i>
      <span>RF出告警</span>
    </div>
    <div class="title">
      <i class="fa-regular fa-lightbulb"></i>
      <span>光入告警</span>
    </div>
    <div class="title">
      <i class="fa-solid fa-lightbulb"></i>
      <span>光出告警</span>
    </div>

    <div class="border">
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
    <div class="nums">6</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.app-dashboard-detail-alarm {
  padding: 40px 0;
  position: relative;

  .border {
    width: 160px;
    height: 160px;
    clip-path: url(#_detail_alarm_round);
    background: radial-gradient();
    margin: 0 auto;
    position: relative;
    > .item {
      position: absolute;
      width: 160px;
      height: 160px;
      top: -80px;
      left: 80px;
      transform-origin: 0% 100%;
      &:nth-child(1) {
        transform: skewY(-30deg);
        background-color: #cc0000;
      }
      &:nth-child(2) {
        transform: rotate(60deg) skewY(-30deg);
        background-color: #78eefc;
      }
      &:nth-child(3) {
        transform: rotate(120deg) skewY(-30deg);
        background-color: #78eefc;
      }
      &:nth-child(4) {
        transform: rotate(180deg) skewY(-30deg);
        background-color: #78eefc;
      }
      &:nth-child(5) {
        transform: rotate(240deg) skewY(-30deg);
        background-color: #78eefc;
      }
      &:nth-child(6) {
        transform: rotate(300deg) skewY(-30deg);
        background-color: #78eefc;
      }
    }
  }

  .nums {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    background-color: #cc0000;
    color: white;
    font-size: 32px;
    box-shadow: 0 0 0 8px #cc000099, 0 0 0 16px #cc000033;
  }

  .title {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #78eefc;
    > i {
      font-size: 1.2rem;
      margin: 0 0.25rem;
    }
    > span {
      font-size: 0.8rem;
      font-weight: bold;
    }

    &:nth-child(1) {
      top: 25px;
      left: 60%;
      color: #ff3600;
    }

    &:nth-child(2) {
      top: 105px;
      left: 72%;
    }

    &:nth-child(3) {
      top: 190px;
      left: 60%;
    }

    &:nth-child(4) {
      top: 190px;
      right: 60%;
      flex-direction: row-reverse;
      > i {
        transform: rotate(180deg);
      }
    }

    &:nth-child(5) {
      top: 105px;
      right: 72%;
      flex-direction: row-reverse;
    }

    &:nth-child(6) {
      top: 25px;
      right: 60%;
      flex-direction: row-reverse;
    }
  }
}
</style>