<template>
  <div class="h c app-component-dot" :class="[type, {same: same}]">
    <div class="_dot no-flex" :style="[!!color ? {backgroundColor: color} : {}, {width: `${size}px`, height: `${size}px`}, dotStyle]"></div>
    <div class="text" :style="same && !!color ? {color: color} : {}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default"
    },
    dotStyle: Object,
    color: String,
    same: Boolean,
    size: {
      type: Number,
      default: 10
    }
  }
};
</script>

<style lang="scss">
.app-component-dot {
  > ._dot {
    border-radius: 50%;
    background-color: var(--color-white);
  }

  > .text {
    margin-left: 5px;
  }

  &.disabled {
    &.same {
      color: var(--color-text-placeholder);
    }

    > ._dot {
      background-color:  var(--color-text-placeholder);
    }
  }

  &.gray, &.info {
    &.same {
      color:  var(--color-text-secondary);
    }

    > ._dot {
      background-color: var(--color-text-secondary);
    }
  }

  &.dark {
    &.same {
      color: var(--color-text-primary);
    }

    > ._dot {
      background-color:var(--color-text-primary);
    }
  }

  &.primary {
    &.same {
      color: var(--color-primary);
    }

    > ._dot {
      background-color: var(--color-primary);
    }
  }

  &.success {
    &.same {
      color: var(--color-success);
    }

    > ._dot {
      background-color: var(--color-success);
    }
  }

  &.warning {
    &.same {
      color:var(--color-warning);
    }

    > ._dot {
      background-color:var(--color-warning);
    }
  }

  &.danger, &.error {
    &.same {
      color: var(--color-danger);
    }

    > ._dot {
      background-color: var(--color-danger);
    }
  }
}
</style>